import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { ReactComponent as Clock } from "../../../assets/images/clock.svg";
import moment from "moment";
import { checkIfHtml, parseHTML, truncateDescription } from "../../../utils";
import { useNavigate } from "react-router-dom";
import DummyBlogImage from "../../../assets/images/dummy-blog-image.png";

const BlogCard = ({
  id,
  image,
  tag,
  date,
  category,
  title,
  description,
  slug,
}) => {
  const navigate = useNavigate();
  const data = {
    id,
    image,
    tag,
    date,
    category,
    title,
    description,
    slug,
  };

  const handleReadMore = () => {
    navigate(`/blogs/${slug}`, {
      state: data,
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        boxShadow={"0px 4px 12px 0px rgba(143, 155, 178, 0.20)"}
        borderRadius={"4px"}
        bgcolor={"#fff"}
        // height={"474px"}
      >
        <Box
          width={"100%"}
          // height={{ xs: "120px", sm: "200px" }}
          position={"relative"}
        >
          <img
            src={image || DummyBlogImage}
            alt="card_image"
            style={{ maxWidth: "100%", height: "auto",cursor:"pointer" }}
            onClick={handleReadMore}
          />
          {/* <Box
            position={"absolute"}
            bottom={"10px"}
            left={"12px"}
            display={"inline-flex"}
            padding={"2px 8px"}
            style={{
              background: "linear-gradient(180deg, #576EDB 0%, #8957DB 100%)",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={400} color={"#fff"}>
              {tag}
            </Typography>
          </Box> */}
        </Box>
        <Box padding={"15px"}>
          <Box display={"flex"} alignItems={"center"}>
            <Clock />
            <Typography
              fontSize={{ xs: "12px", sm: "14px" }}
              fontWeight={400}
              color={"#A1A7BC"}
              ml={1}
            >
              {/* 25 March 2024 */}
              {moment(date).format("DD MMMM YYYY")}
            </Typography>
          </Box>
          <Box
            // bgcolor={"#576edb"}
            display={"inline-flex"}
            alignItems={"center"}
            padding={"5px 10px"}
            borderRadius={"30px"}
            my={"3px"}
            border={"1px solid #A1A7BC"}
          >
            <Typography
              letterSpacing={"0.5px"}
              fontSize={"12px"}
              color={"#A1A7BC"}
              fontWeight={600}
            >
              {category}
            </Typography>
          </Box>
          {title && checkIfHtml(title) ? (
            <div
              style={{
                margin: "5px 0 10px 0",
                fontSize: "20px",
                fontWeight: "700",
                color: "#202020",
                lineHeight: "32px",
                width: "95%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                title,
              }}
              dangerouslySetInnerHTML={parseHTML(
                truncateDescription(title, 200)
              )}
            ></div>
          ) : (
            <Box marginBottom={"10px"} marginTop={"5px"}>
              <Typography
                fontSize={"20px"}
                fontWeight={700}
                color={"#202020"}
                lineHeight={"32px"}
                width={"95%"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                title={title}
              >
                {title}
              </Typography>
            </Box>
          )}

          {description && checkIfHtml(description) ? (
            <div
              style={{
                margin: "5px 0 10px 0",
                height: "100px",
                overflow: "hidden",
                fontSize: "16px",
                fontWeight: "400",
                color: "#202020",
                lineHeight: "160%",
              }}
              dangerouslySetInnerHTML={parseHTML(
                truncateDescription(description, 200)
              )}
            ></div>
          ) : (
            <Box
              marginBottom={"10px"}
              marginTop={"5px"}
              border={"1px solid black"}
              height={"150px"}
              overflow={"hidden"}
            >
              <Typography
                fontSize={"16px"}
                fontWeight={400}
                color={"#202020"}
                lineHeight={"160%"}
              >
                {description}
              </Typography>
            </Box>
          )}

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={"20px"}
          >
            <Button
              sx={{
                width: { xs: "100%", sm: "140px" },
                // width: "125px",
                borderRadius: "30px",
                background: "#576EDB",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "14px 16px",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                "&:hover": {
                  background: "#576EDB",
                },
              }}
              onClick={handleReadMore}
            >
              Read More
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BlogCard;
