import { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { returnRoleValue } from "./utils";
import { setPortalInfo } from "./redux/globalSlice";
import ReactGA from 'react-ga4';

// LAYOUTS
import AppLayout from "./pages/AppLayout";
import PageNotFound from "./components/NotFound/PageNotFound";

//OTHER
import { routes } from "./routes/Routes";
import { setLogin, setSignUp, setToken } from "./redux/authSlice";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { setProfilePhoto } from "./redux/profileSlice";

// CSS
import "./App.css";

function App() {
  const dispatch = useDispatch();
  const { portalName } = useSelector((state) => state.app);
  ReactGA.initialize('G-DEQBXEC5E8');

  useEffect(() => {
    const portal = localStorage.getItem("portal");
    let lsToken = localStorage.getItem("token");
    let user_data = localStorage.getItem("login_data")
      ? JSON.parse(localStorage.getItem("login_data"))
      : null;

    if (portal) {
      dispatch(
        setPortalInfo({
          portalName: portal,
          portalValue: returnRoleValue(portal),
        })
      );
    }

    if (lsToken) {
      dispatch(setToken(lsToken));

      if (portalName !== portal) {
        dispatch(
          setPortalInfo({
            portalName: user_data?.type || portal,
            portalValue: returnRoleValue(user_data?.type || portal),
          })
        );
      }
    }

    if (user_data) {
      user_data?.profile_photo &&
        dispatch(
          setProfilePhoto({
            profilePhoto: user_data?.profile_photo,
          })
        );
      dispatch(
        setPortalInfo({
          portalName: user_data?.type,
          portalValue: returnRoleValue(user_data?.type),
        })
      );
      localStorage.setItem("portal", user_data?.type);
      dispatch(setLogin(user_data));
      dispatch(setSignUp(user_data));
    }
      
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
  }, []);

  const renderComponentBasedOnType = (routeType, component) => {
    switch (routeType) {
      case "isPrivateRoute":
        return <PrivateRoute>{component}</PrivateRoute>;
      case "isPublicRoute":
        return <PublicRoute>{component}</PublicRoute>;
      default:
        return component;
    }
  };

  const renderRoutes = () => {
    return routes.map(
      (
        {
          path,
          element = null,
          exact = true,
          hasIndex = false,
          parentLayout = "",
          routeType = "isPrivateRoute",
          childRoutes = [],
        },
        idx
      ) => (
        <Route
          key={idx}
          {...(hasIndex && { path: path })}
          element={<AppLayout layout={parentLayout} />}
          {...(exact && { exact: true })}
        >
          {childRoutes &&
            childRoutes.map((elem) => {
              const modifiedElem = {
                ...elem,
                element: renderComponentBasedOnType(routeType, elem.element),
              };
              return <Route {...modifiedElem} />;
            })}
        </Route>
      )
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        {renderRoutes()}
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
