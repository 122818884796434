import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import PolicyCard from "../components/hrPolicyPage/PolicyCard";
import Layout from "../components/Layout/Layout";
import { pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";
import { getJobDescriptions } from "../../redux/LandingPage/LandingPageSlice";
import { JOB_DESCRIPTIONS_PER_PAGE } from "../../routes/constants";
import GreaterThanIcon from "@mui/icons-material/ArrowForward";
import { Helmet } from "react-helmet-async";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const JobDescription = () => {
  const [jobDesc, setJobDesc] = useState([]);
  const dispatch = useDispatch();
  const recordsPerPage = JOB_DESCRIPTIONS_PER_PAGE;
  const [pageNo, setPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    getAllLocationsList();
  }, [pageNo]);

  const getAllLocationsList = () => {
    dispatch(getJobDescriptions({ pageNo, recordsPerPage }))
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data;
        const metaData = res?.payload?.meta;
        setJobDesc((prev) => [...prev, ...apiData]);
        setTotalRecords(metaData?.total_records);
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleViewMore = () => {
    setPageNo((prev) => prev + 1);
  };
  return (
    <>
    <Helmet>
        <title>Job Description | OctaHire</title>
        <meta name="description" content="Looking professionals for your next project. Simplify hiring processes and find the
        ideal candidates for your team with OctaHire. We support your staffing needs."/>
        <link rel="canonical" href="https://octahire.com/job-description" />
      </Helmet>
      <Layout>
        <Box
          marginTop={{ xs: "75px", sm: "80px" }}
          backgroundColor={"#F1F3FC"}
          textAlign={"center"}
          padding={{ xs: "30px 0px", sm: "40px 0px" }}
          // display={"flex"}
          // justifyContent={"center"}
          // alignItems={"center"}
          // height={{ xs: "94px", sm: "188px" }}
        >
          <Typography
            fontSize={{ xs: "20px", sm: "24px" }}
            fontWeight={700}
            color={"#141621"}
          >
            Job Description
          </Typography>
          <Typography className={"subheading oh-description"} style={{ color: "#9ca1b2"}}>
          Crafting better job descriptions attracts top candidates. Our SEO-optimized, 
          job board-approved templates increase visibility and streamline the hiring process.
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          paddingX={{ xs: "20px", sm: "40px" }}
          paddingY={"20px"}
          // marginBottom={{ xs: "40px", sm: "80px" }}
          marginTop={{ xs: "10px" }}
        >
          {jobDesc?.map((policy) => {
            const data = policy?.attributes;
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <PolicyCard
                  id={policy?.id}
                  image={data?.image?.url}
                  date={data?.created_at}
                  title={data?.title}
                  docURL={data?.document?.url}
                  docName={"jobDescription"}
                />
              </Grid>
            );
          })}
        </Grid>
        {jobDesc?.length < totalRecords && (
          <Box
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={"40px"}
            marginBottom={"80px"}
          >
            <Button
              endIcon={<GreaterThanIcon />}
              style={{
                border: "1px solid #DEE4FF",
                borderRadius: "30px",
                padding: "12px 24px",
                color: "#445386",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "none",
                cursor: "pointer",
              }}
              onClick={handleViewMore}
            >
              View More
            </Button>
          </Box>
        )}
      </Layout>
    </>
  );
};

export default JobDescription;
