import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useNavigate } from "react-router-dom";
import "./index.scss";

const PrimaryMenu = ({ menuTitle, navigateTo, menuItems, onClose }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (onClose) {
            onClose();
        }
    };

    const handleMenuItemClick = (item) => {
        if (item.to) {
            navigate(item.to);
            handleClose();
        }
    };

    return (
        <div className="oh-menu-btn">
            <Button
                id={`${menuTitle.toLowerCase()}-button`}
                aria-controls={open ? `${menuTitle.toLowerCase()}-menu` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => menuItems?.length > 0 ? handleClick(e) : navigate(navigateTo)}
                endIcon={menuItems && menuItems.length > 0 && <KeyboardArrowDownIcon />}
            >
                {menuTitle}
            </Button>
            {menuItems && menuItems.length > 0 &&
                <Menu
                    id={`${menuTitle.toLowerCase()}-menu`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': `${menuTitle.toLowerCase()}-button`,
                    }}
                    className="oh-menu-ul"
                >
                    {menuItems &&
                        menuItems.map((item, index) => (
                            <MenuItem key={index} onClick={() => handleMenuItemClick(item)} className="oh-menu-item">
                                {item.title}
                            </MenuItem>
                        ))}
                </Menu>
            }
        </div>
    );
};

export default PrimaryMenu;
