import React from "react";
import moment from "moment";

//MUI
import Box from "@mui/material/Box";
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";
import MuiIconChip from "../../../../mui/Chip/IconChip";
import ExtrasTooltip from "../../../../components/Tooltip/ExtrasTooltip";
import OutlinedChip from "../../../../components/customChips/OutlinedChip";
import MuiTooltip from "../../../../mui/Tooltip/MuiTooltip";

//ASSETS
import AirBnbIcon from "../../../../assets/images/Airbnb.svg";
import LocationIcon from "../../../../assets/images/location.svg";
import SuitcaseIcon from "../../../../assets/images/suitcaseIcon.svg";
import CurrencyRupeeIcon from "../../../../assets/images/ruppeeIcon.svg";

// CSS
import "./index.scss";

const CardHeader = ({
  location = "Indore Bhopal, Jabalpur +3",
  tags = [],
  jobData,
}) => {
  const jobDetails = jobData?.attributes;
  const locations = jobDetails?.locations
    ?.slice(0, 2)
    .map((location) => location.name)
    .join(", ");
  const remainingLocationsCount = jobDetails?.locations?.length - 2;
  const remainingLocations = jobDetails?.locations
    ?.slice(2)
    .map((elem) => elem.name)
    .join(", ");
  return (
    <>
      <Box className="oh-details-card-header">
        <img className="airbnbImage" src={jobDetails?.employer?.profile_photo?.url || AirBnbIcon}
          alt={"logo"} />
        <Box className="job-desc-box">
          <span className="oh-title">
            <MuiTooltip
              title={jobDetails?.job_title}
              length={25}
            />
          </span>

          <MuiTypography
            content={
              jobDetails?.organization_name || jobDetails?.category?.name
            }
            className="oh-subtitle"
          />
        </Box>

        <span className="oh-applied-status-chip">
          {(jobDetails?.application_status && jobDetails?.application_status != "") && (
            <OutlinedChip
              size={"small-100"}
              label={jobDetails?.application_status}
              backgroundColor={"#C5F7D2"}
              borderColor={"#34A853"}
              color={"#34A853"}
            />
          )}
        </span>
      </Box>
      <Box className="oh-card-body">
        {jobDetails?.work_experience && (
          <MuiTypography variant="span" className="oh-requirements">
            <img src={SuitcaseIcon} className="suitcase-icon" alt={"work-exp"} /> {jobDetails?.work_experience}
          </MuiTypography>
        )}

        {jobDetails?.ctc && (
          <MuiTypography variant="span" className="oh-requirements">
            <img src={CurrencyRupeeIcon} className="rupee-icon" alt={"salary"} /> {jobDetails?.ctc}
          </MuiTypography>
        )}
        {location && (
          <MuiTypography className="oh-requirements">
            <img src={LocationIcon} className="location-icon" alt={"locations"} />
            {locations}
            {remainingLocationsCount > 0 && (
              <ExtrasTooltip
                count={remainingLocationsCount}
                tooltipText={remainingLocations}
              />
            )}
          </MuiTypography>
        )}

        {jobDetails?.created_at && (
          <MuiTypography
            className="oh-posted-text"
            content={`Posted: ${moment(jobDetails?.created_at).fromNow()}`}
          />
        )}

        {tags.length > 0 && (
          <Box className="oh-chip-box">
            {tags.map((elem, idx) => {
              return <MuiIconChip key={idx} label={elem} />;
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

export default CardHeader;
