import React from "react";

//MUI
import Box from "@mui/material/Box";

//ASSETS
import Ellipse from "../../../../assets/images/Ellipse.svg";
import Location from "../../../../assets/images/location.svg";
import AirBnbIcon from "../../../../assets/images/Airbnb.svg";


//OTHER
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";
import ExtrasTooltip from "../../../Tooltip/ExtrasTooltip";
import { returnSubstring } from "../../../../utils";
import MuiTooltip from "../../../../mui/Tooltip/MuiTooltip";

const ReferCardHeader = (elem) => {
    const location = elem?.locations
        ?.slice(0, 2)
        .map((location) => location.name)
        .join(", ");
    const remainingLocationsCount = elem?.locations?.length - 2;
    const remainingLocations = elem?.locations
        ?.slice(2)
        .map((elem) => elem.name)
        .join(", ");

    return (
        <Box className="card-header">
            <img src={elem?.image || AirBnbIcon} alt="card-img" className="card-img" />
            <Box display={'block'} px={1}>
                <MuiTypography className="card-name" content={returnSubstring(elem?.title, 22)} />
                <Box className="oh-locations" width={'100%'} display={"flex"} flexWrap={"wrap"} alignContent={'center'}>

                    {location && (
                        <MuiTypography style={{ cursor: "pointer" }} className="oh-requirements">
                            <img src={Location} alt="places" />
                            <MuiTooltip
                                displayTitleOnly={returnSubstring(location, 25)}
                                description={location}
                            />
                            {remainingLocationsCount > 0 && (
                                <ExtrasTooltip
                                    count={remainingLocationsCount}
                                    tooltipText={remainingLocations}
                                />
                            )}
                        </MuiTypography>
                    )}

                    {elem?.experience &&
                        <MuiTypography className="card-experienced">
                            <img src={Ellipse} alt="Ellipse" />
                            {elem?.experience}
                        </MuiTypography>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default ReferCardHeader;  