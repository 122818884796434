import React from "react";
import { Typography, Box, Grid, Link } from "@mui/material";
import logo from "../../../assets/images/logo.svg";
import { ReactComponent as Facebook } from "../../../assets/images/facebook-Icon.svg";
import { ReactComponent as LinkedIn } from "../../../assets/images/linkedin-Icon.svg";
import { ReactComponent as Twitter } from "../../../assets/images/twitter.svg";
import { ReactComponent as Instagram } from "../../../assets/images/instagram-Icon.svg";
import { ReactComponent as Youtube } from "../../../assets/images/youtube-Icon.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';

function Footer() {
  const linkStyles = {
    fontSize: "14px",
    fontWeight: 400,
    color: "#445386",
    textDecoration: "none",
    marginBottom: "15px",
    width: "150px",
    "&:hover": {
      color: "#203389",
      fontWeight: "600",
    },
  };
  const navigate = useNavigate();
  const isSm = useMediaQuery('(max-width:600px)');

  const socialMediaLinks = [
    {
      name: "Facebook",
      url: "https://www.facebook.com/octahireofficial/",
      icon: <Facebook />,
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/octa-hire/",
      icon: <LinkedIn />,
    },
    {
      name: "Twitter",
      url: "https://twitter.com/OctaHire_jobs",
      icon: <Twitter />,
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/octahire.jobs/",
      icon: <Instagram />,
    },
    {
      name: "Youtube",
      url: "https://www.youtube.com/channel/UCTex90k3i3LKcX_0FbBkL1Q",
      icon: <Youtube />,
    },
  ];
  return (
    <>
      <Box
        // sx={{
        //       bottom: "45px",
        //       padding: "32px",
        //       position: "fixed",
        //       left: 0,
        //       right: 0,
        // }}
        bgcolor="#DEE4FF"
        color="#fff"
        p={4}
      >
        <Grid container spacing={3}>
          {/* Logo and Social Media Icons */}
          <Grid
            width={"100%"}
            item
            xs={12}
            sm={6}
            md={3}
            display={{ xs: "flex" }}
            justifyContent={{ xs: "space-between", sm: "flex-start" }}
            flexDirection={{ xs: "row", sm: "column" }}
          >
            <Box marginBottom={"15px"} marginLeft={{ xs: "-14px", sm: "" }}>
              <img className="main-logo" src={logo} alt="logo" width={isSm ? "100px" : "170px"} />
            </Box>
            <Box>
              <Typography color={"#000"} fontWeight={400} fontSize={"14px"}>
                Connect with us
              </Typography>
              <Box display="flex" mt={1}>
                {socialMediaLinks.map((socialMedia, index) => (
                  <a
                    key={index}
                    href={socialMedia.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      key={index}
                      width={"24px"}
                      height={"24px"}
                      borderRadius={"4px"}
                      backgroundColor="#445386"
                      padding={"2px"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      mr={1}
                      sx={{
                        "&:hover": {
                          border: "1px solid rgb(87, 110, 219)",
                          transform: "scale(1.2)",
                        },
                      }}
                      cursor="pointer"
                    >
                      {socialMedia.icon}
                    </Box>
                  </a>
                ))}
              </Box>
            </Box>
          </Grid>
          {/* Important Links */}
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            display={"flex"}
            flexDirection={"column"}
            className="footerColumn"
          >
            <Typography
              variant="h6"
              mb={2}
              fontSize={"18px"}
              fontWeight={700}
              color="#445386"
            >
              Important Links
            </Typography>
            <Link href="/jobseekers" mb={1} sx={linkStyles}>
              Job Seeker
            </Link>
            <Link href="/employers" mb={1} sx={linkStyles}>
              Employer
            </Link>
            <Link href="/freelancers" mb={1} sx={linkStyles}>
              Freelancer
            </Link>
            <Link href="/faq" mb={1} sx={linkStyles}>
              FAQs
            </Link>
          </Grid>
          {/* Legal Links */}
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            display={"flex"}
            flexDirection={"column"}
            className="footerColumn"
          >
            <Typography
              variant="h6"
              mb={2}
              fontSize={"18px"}
              fontWeight={700}
              color="#445386"
            >
              Legal Links
            </Typography>
            <Link href="/privacy-policy" color="inherit" mb={1} sx={linkStyles}>
              Privacy Policy
            </Link>
            <Link href="/term-condition" color="inherit" mb={1} sx={linkStyles}>
              Terms & Conditions
            </Link>
          </Grid>
          {/* Quick Links */}
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            display={"flex"}
            flexDirection={"column"}
            className="footerColumn"
          >
            <Typography
              variant="h6"
              mb={2}
              fontSize={"18px"}
              fontWeight={700}
              color="#445386"
            >
              Quick Links
            </Typography>
            <Link href="/contact-us" mb={1} sx={linkStyles}>
              Contact Us
            </Link>
            {/* <Link href="#" mb={1} sx={linkStyles}>
              Careers Dashboard
            </Link> */}
            <Link href="/blogs" mb={1} sx={linkStyles}>
              Blog
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box
        backgroundColor="#DEE4FF"
        textAlign="center"
        borderTop={"1px solid #fff"}
        padding={0}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        py={"15px"}
      // sx={{
      //   bottom: 0,
      //   height: "45px",
      //     position: "static",
      //     left: 0,
      //     right: 0,
      // }}
      >
        <Typography color="#445386" fontSize={"14px"} fontWeight={400}>
          &copy; All Rights Reserved - Octahire
        </Typography>
      </Box>
    </>
  );
}

export default Footer;
