import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
//MUI
import Box from "@mui/material/Box";
//ASSETS
import Location from "../../../assets/images/location.svg";
import BookIcon from "../../../assets/images/book.svg";
//OTHER
import {
  parseHTML,
  returnSubstring,
  truncateDescription,
} from "../../../utils";
import FilledChip from "../../customChips/FilledChips";
import {
  clearSelectedCandidates,
} from "../../../redux/Freelancer/allotedJobsSlice";
import MuiTooltip from "../../../mui/Tooltip/MuiTooltip";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import ExtrasTooltip from "../../Tooltip/ExtrasTooltip";
//CSS
import "./index.scss";

const AllotedJobsCard = ({
  handleNavigate = () => { },
  timestamp = false,
  data = {},
  cardType = "Dashboard",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, locations, experience } = data;
  const location = locations
    ?.slice(0, 2)
    .map((elem) => elem.name)
    .join(", ");
  const remainingLocationsCount = locations?.length - 2;
  const remainingLocations = locations
    ?.slice(2)
    .map((elem) => elem.name)
    .join(", ");

  return (
    <>
      <Box className="oh-secondary-card-container">
        <Box className="oh-alloted-card">
          <Box onClick={handleNavigate} className="oh-card-header">
            <Box
              sx={{ lineHeight: "20px" }}
              className="job-desc-box job-info-no-flex"
            >
              <MuiTypography
                content={
                  <MuiTooltip
                  title={data?.title}
                  length={20}
                  />
                }
                className="oh-title"
              />
              {data?.description && (
                <div
                  className="oh-subtitle"
                  dangerouslySetInnerHTML={parseHTML(
                    truncateDescription(data?.description, 40)
                  )}
                />
              )}
              {timestamp && (
                <MuiTypography content={timestamp} className="oh-timestamp" />
              )}
            </Box>
          </Box>

          <Box className="oh-card-body">
            <MuiTypography className="oh-requirements">
              <img src={Location} alt="places" className="alloted-location-icon" />
              <MuiTooltip
                  displayTitleOnly={returnSubstring(location, 25)}
                  description={location}
              />
              {remainingLocationsCount > 0 && (
                <ExtrasTooltip
                  count={remainingLocationsCount}
                  tooltipText={remainingLocations}
                />
              )}
            </MuiTypography>

            <MuiTypography className="oh-requirements">
            <img src={BookIcon} alt="experience" className="alloted-location-icon" /> {experience}
            </MuiTypography>
          </Box>

          <Box className="oh-alloted-jobs-card-footer">
            {cardType === "Dashboard" && (
              <MuiTypography
                sx={{ background: "#fff" }}
                className="posted_txt"
                variant="span"
                content={`Posted: ${moment(data?.created_at).fromNow()}`}
              />
            )}

            {cardType === "allJobs" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: 400,
                  fontSize: "14px",
                  width: "100%",
                }}
              >
                <span
                  style={{ fontSize: "14px", color: "var(--oh-gray-color)" }}
                >
                  {data?.freelancer_candidates?.length
                    ? "Candidates: "
                    : "Applicants: "}
                  <b style={{ color: "var(--oh-blue-300-color)" }}>
                    {" "}
                    {data?.freelancer_candidates?.length ||
                      data?.applicants_count}
                  </b>
                </span>

                <span>
                  <FilledChip
                    size="medium-200"
                    sx={{
                      fontSize: "13px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      dispatch(clearSelectedCandidates());
                      navigate(`/allotted-jobs/job-details/${id}`);
                    }}
                    label={"Add Candidate"}
                    color="var(--oh-blue-color)"
                  />
                </span>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AllotedJobsCard;
