import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//MUI
import Box from "@mui/material/Box";

//OTHER
import Sidebar from "../../../components/Sidebar/Sidebar";
import MuiBreadcrumb from "../../../mui/BreadCrumbs/MuiBreadcrumbs";
import ApplicantList from "../../../components/ApplicantList";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import { useSelector } from "react-redux";

// CSS
import "../layout.scss";
import Loader from "../../../components/Loader/Loader";
import NotAuthorized from "../../../components/NotFound/NotAuthorized";
import Header from "../../../components/Header/Header";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";

const EmployerLayout = ({ children, showRightSection = true }) => {
  const location = useLocation();

  const { portalName } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.removeItem("form_type");
    localStorage.removeItem("auth_via");

    // Mimic async state fetching/updating preventing default not authorized page to show
    setTimeout(() => {
      setLoading(false);
    }, 10);
  }, [portalName]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {portalName === "Employer" ? (
        <>
          <Header />
          <Box
            className={
              showRightSection
                ? "oh-employer-background-setting"
                : "oh-background-setting"
            }
          >
            <Sidebar className="primary-navigation-bar" />
            <Box>
              {location.pathname !== "/employer" && <MuiBreadcrumb />}
              {children}
            </Box>
            {showRightSection && <Box
              className="oh-right-section"
              sx={{
                position: "sticky",
                top: "100px",
                display: "flex",
                gap: "18px",
                padding: "0px",
                height: "calc(100vh - 140px)",
                overflow: "auto",
              }}
            >
              <MuiDivider
                className="oh-vertical-divider"
                orientation="vertical"
              />
              <Box
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <MuiTypography
                  content="New Applicants"
                  className="oh-heading"
                />
                <ApplicantList />
              </Box>
            </Box>}
          </Box>
        </>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
};

export default EmployerLayout;
