import React, { useEffect, useState } from "react";
import "./index.scss";
//MUI
import Box from "@mui/material/Box";
// OTHER
import CardHeader from "./Components/CardHeader";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import MuiButtonContained from "../../../mui/Buttons/MuiButtonContained";
import Skills from "./Components/Skills";
import ApplicationStatus from "./Components/ApplicationStatus";
import JobSeekerLayout from "../../Layouts/JobSeeker/JobSeekerLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  jobApply,
  setShowAppliedJobDetail,
  setShowAppliedJobDetails,
  showAppliedJob,
} from "../../../redux/JobSeeker/jobSeekerJobsSlice";
import { Typography } from "@mui/material";
import { Toaster } from "../../../components/Toaster/Toaster";
import moment from "moment";
import { setFillProfileFirst, setIsLoading } from "../../../redux/globalSlice";
import FillProfileDialogBox from "../../../components/Dialog/FillProfileDialogBox";
import RenderJD from "../../Employer/JobDetails/RenderJD";
import InfoBox from "../../../components/OtherInfoForDetails";
import GlobalLoader from "../../../components/Loader/GlobalLoader";

const Portal2JobDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobData, setJobData] = useState();

  const [showFullDescription, setShowFullDescription] = useState(false);
  const showAppliedJobDetail = useSelector(
    (state) => state.appliedJobs.showAppliedJobDetail
  );

  const { isLoading } = useSelector(state => state.app);

  const { fillProfileFirstPopup } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);

  const jobDetailsData = jobData?.attributes;
  const skillsData = jobDetailsData?.skills.map((elem) => elem?.name);

  useEffect(() => {
    dispatch(setIsLoading(true));
    showJobPostDetail();
  }, [params?.id]);

  const showJobPostDetail = () => {
    dispatch(showAppliedJob(params?.id))
      .then((res) => {
        if (res?.type === "showAppliedJob/fulfilled") {
          setJobData(res?.payload?.data);
          dispatch(setIsLoading(false));
          dispatch(setShowAppliedJobDetail(res?.payload?.data));
        } else if (res?.type === "showAppliedJob/rejected") {
          dispatch(setIsLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        console.log(err);
      });
  };

  const toggleDescription = () => {
    showFullDescription && window.scrollTo(0, 0);
    setShowFullDescription(!showFullDescription);
  };

  const handleApply = () => {
    const data = {
      data: { job_post_id: params?.id },
    };
    dispatch(jobApply({ data }))
      .then((res) => {
        if (res.type === "jobApply/fulfilled") {
          Toaster.TOAST(res.payload?.message, "success");
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
    navigate("/jobseeker");
  };

  const handleClose = () => {
    dispatch(setFillProfileFirst(false));
  };

  const handleSubmitBasedOnProfile = () => {
    if (user?.profile_complete) {
      handleApply();
      dispatch(setFillProfileFirst(false));
    } else {
      dispatch(setFillProfileFirst(true));
    }
  };

  return (
    <>
      <JobSeekerLayout>
        {
          isLoading ? <GlobalLoader minHeight="350px" /> : (
            <Box className="oh-job-details-container">
              <CardHeader jobData={jobData} />
              <MuiDivider className="oh-divider" />
              {jobDetailsData?.application_status === "applied" ||
                jobDetailsData?.application_status === "viewed" ||
                jobDetailsData?.application_status === "reviewed" ? (
                <>
                  <ApplicationStatus title="Application Status" />
                  <MuiDivider className="oh-divider" />
                </>
              ) : null}

              <h3 style={{ margin: "20px 0 10px" }}>Job Description</h3>
              <RenderJD
                job_description={jobDetailsData?.job_description}
                showFullDescription={showFullDescription}
                toggleDescription={toggleDescription}
              />

              <h3 style={{ margin: "30px 0 0px" }}>Skills</h3>
              <Skills size="medium-200" skillsData={skillsData} />

              <MuiDivider className="oh-divider" />

              <h3 style={{ margin: "30px 0 0px" }}>Other Information</h3>
              <Box
                style={{
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >

                <InfoBox
                  items={[
                    {
                      label: "Min. Education Required :-",
                      value: jobDetailsData?.education?.education_name || "-",
                    },
                    {
                      label: "Experience Required :-",
                      value: jobDetailsData?.work_experience || "-",
                    },
                    {
                      label: "Work Mode :-",
                      value: jobDetailsData?.work_mode || "-",
                    },
                    {
                      label: "No. of vacancies :-",
                      value: jobDetailsData?.no_of_vacancies || "-",
                    },
                    { label: "CTC Offered :-", value: jobDetailsData?.ctc ? `₹ ${jobDetailsData?.ctc}` : "-" },
                    {
                      label: "Designation:-",
                      value: jobDetailsData?.designation || "-",
                    },
                  ]}
                />
              </Box>

              <MuiDivider className="oh-secondary-divider" />
              {jobDetailsData?.application_status === "applied" ||
                jobDetailsData?.application_status === "viewed" ||
                jobDetailsData?.application_status === "reviewed" ? (
                <Box className="already-applied-text" sx={{ mt: 2, textAlign: "start" }}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontSize: "13px", fontStyle: "italic" }}
                  >
                    {`You have already applied for this job on ${moment(
                      jobDetailsData?.applied_at
                    ).format("MMM DD,YYYY")}`}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ mt: 2 }}>
                  <MuiButtonContained
                    className="oh-apply-btn"
                    title="Apply"
                    // onClick={handleApply}
                    onClick={handleSubmitBasedOnProfile}
                  />
                </Box>
              )}
            </Box>
          )
        }
      </JobSeekerLayout>
      {fillProfileFirstPopup && (
        <FillProfileDialogBox
          heading={`Please fill your profile to continue applying to this job!`}
          popupState={fillProfileFirstPopup}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default Portal2JobDetails;
