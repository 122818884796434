import React from "react";
import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
//ASSETS
import AirBnbIcon from "../../../assets/images/Airbnb.svg";
import LocationIcon from "../../../assets/images/location.svg";
import BookIcon from "../../../assets/images/book.svg";
//OTHER
import { returnSubstring } from "../../../utils";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import MuiIconChip from "../../../mui/Chip/IconChip";
import ExtrasTooltip from "../../Tooltip/ExtrasTooltip";
import FilledChip from "../../customChips/FilledChips";
import MuiTooltip from "../../../mui/Tooltip/MuiTooltip";
//CCSS
import "./index.scss";

const JobInfoCard = ({
  onClick = () => { },
  onApplyClick = () => { },
  tags = false,
  data = {},
  cardType = "dashboard",
  className = "",
}) => {
  const { id, locations } = data;
  const location = locations
    .slice(0, 2)
    .map((elem) => elem.name)
    .join(", ");
  const remainingLocationsCount = locations.length - 2;
  const remainingLocations = locations
    .slice(2)
    .map((elem) => elem.name)
    .join(", ");
  return (
    <>
      <Box className={`oh-secondary-card-container ${className}`}>
        <Box onClick={() => onClick(id)} className="oh-secondary-card">
          <Box className="oh-card-header">
            <img
              src={data?.employer?.profile_photo?.url || AirBnbIcon}
              alt={"logo"}
            />
            <Box className="job-desc-box job-info-no-flex">

              <MuiTypography
                content={
                  <MuiTooltip
                    length={16}
                    title={data?.job_title}
                  />
                }
                className="oh-title"
              />
              <MuiTypography
                content={
                  returnSubstring(data?.employer?.organization_name || data?.organization_name || data?.category?.name, 25)
                }
                className="oh-subtitle"
              />
            </Box>
            {data?.created_at && (
              <MuiTypography
                content={moment(data?.created_at).fromNow()}
                className="oh-timestamp"
              />
            )}
          </Box>
          <Box className="oh-card-body">
            <MuiTypography className="oh-requirements">
              <img src={LocationIcon} className="location-icon" alt={"locations"} />
              <MuiTooltip
              displayTitleOnly={returnSubstring(location, 27)}
              description={location}
              />

              {remainingLocationsCount > 0 && (
                <ExtrasTooltip
                  count={remainingLocationsCount}
                  tooltipText={remainingLocations}
                />
              )}
            </MuiTypography>

            <MuiTypography className="oh-requirements">
              <img src={BookIcon} alt="experience" className="location-icon" />
               {data?.work_experience}
            </MuiTypography>
            {tags && (
              <Box className="oh-chip-box">
                {["Java", "PHP", "HTML", "CSS"]?.map((elem, idx) => {
                  return <MuiIconChip key={idx} label={elem} />;
                })}
              </Box>
            )}
          </Box>
        </Box>
       
        <Box
          sx={{ marginBottom: "20px" }}
          className="oh-jobseeker-jobs-card-footer"
        >
          {cardType === "suggestedJobs" &&
            (data?.application_status === "applied" ||
              data?.application_status === "viewed" ||
              data?.application_status === "reviewed" ? (
              <Box sx={{ mt: 0, textAlign: "start" }}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ fontSize: "10px", fontStyle: "italic" }}
                >
                  {`You have already applied for this job on ${moment(
                    data?.applied_at
                  ).format("MMM DD,YYYY")}`}
                </Typography>
              </Box>
            ) : (
              <span style={{ cursor: "pointer" }}>
                <FilledChip
                  size="medium-300"
                  onClick={onApplyClick}
                  label={"Apply"}
                  color="var(--oh-blue-color)"
                />
              </span>
            ))}

          {cardType === "dashboard" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: 400,
                fontSize: "14px",
                width: "100%",
              }}
            >
              <MuiTypography
                sx={{ background: "#fff" }}
                className="posted_txt"
                variant="span"
                content={`Posted: ${moment(data?.created_at).fromNow()}`}
              />
              <span style={{ cursor: "pointer" }}>
                <FilledChip
                  onClick={onApplyClick}
                  label={"Apply"}
                  cursor="pointer"
                  color="var(--oh-blue-color)"
                />
              </span>
            </div>
          )}
        </Box>
        {/* </Box> */}
      </Box>
    </>
  );
};

export default JobInfoCard;
