import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { checkIfHtml, parseHTML, truncateDescription } from "../../../utils";
import BlogCard from "../../components/blogPage/BlogCard";
import { useDispatch } from "react-redux";
import {
  getAllBlogs,
  getBlogDetail,
} from "../../../redux/LandingPage/LandingPageSlice";
import moment from "moment";
import { ReactComponent as Clock } from "../../../assets/images/clock.svg";
import "./index.scss";
import DummyBlogImage from "../../../assets/images/dummy-blog-image.png";
import { Helmet } from "react-helmet-async";

let url = `https://octahire.com${window.location.pathname}`

const BlogDetails = () => {
  // const { state: blog, pathname } = useLocation();
  // console.log("loc", blog);

  const location = useLocation();
  const { slug } = useParams();
  const [blog, setBlog] = useState({});
  const [blogs, setBlogs] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getBlogDetails();
    getAllBlogsList();
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  const getAllBlogsList = () => {
    dispatch(getAllBlogs())
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data;
        const blogData = apiData?.filter(
          (blogD) => blogD?.attributes?.slug?.trim() !== slug?.trim()
        );
        setBlogs(blogData);
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getBlogDetails = () => {
    dispatch(getBlogDetail(slug))
      .then((res) => {
        if (res?.error) throw new Error("Something went wrong!");
        const apiData = res?.payload?.data?.attributes;
        setBlog(apiData);
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const parseHTML1stParagraph = (htmlString) => {
    if (htmlString !== undefined) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const firstParagraph = doc.querySelector('p');
      if (firstParagraph) {
        return firstParagraph.innerHTML;
      }
    }
    return "";
  };
  
  return (
    <>
      <Helmet>
        <title>{blog?.meta_title && checkIfHtml(blog?.meta_title) ?
          parseHTML1stParagraph(blog?.meta_title) : blog?.meta_title}</title>
        <meta name="description" content={blog?.meta_description && checkIfHtml(blog?.meta_description) ?
          parseHTML1stParagraph(blog?.meta_description) : blog?.meta_description} />
        <meta name="keywords" content={blog?.meta_keywords && checkIfHtml(blog?.meta_keywords) ?
          parseHTML1stParagraph(blog?.meta_keywords) : blog?.meta_keywords} />
        <link rel="canonical" href={url} />
      </Helmet>
      <Layout>
        <Grid container spacing={3} marginTop={{ xs: "50px", md: "0" }}>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Box
              as="section"
              width={"100%"}
              pl={{ xs: 0, md: "30px" }}
              mt={{ xs: 0, md: "60px" }}
            >
              <img
                src={blog?.photo?.url || DummyBlogImage}
                alt="blog_image"
                style={{
                  width: "100%",
                  // maxHeight: "400px",
                  // objectFit: "cover",
                  // objectPosition: "top",
                }}
              />
            </Box>
            <Box as="section" marginY={"30px"} px={{ xs: "20px", sm: "30px" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Clock />
                    <Typography
                      fontSize={{ xs: "12px", sm: "14px" }}
                      fontWeight={400}
                      color={"#A1A7BC"}
                      ml={1}
                    >
                      {moment(blog?.created_at).format("DD MMMM YYYY")}
                    </Typography>
                  </Box>

                  <Box
                    // bgcolor={"#576edb"}
                    display={"inline-flex"}
                    alignItems={"center"}
                    padding={"5px 10px"}
                    borderRadius={"30px"}
                    letterSpacing={"0.5px"}
                    fontSize={"14px"}
                    color={"#A1A7BC"}
                    border={"1px solid #A1A7BC"}
                    fontWeight={600}
                    ml={"10px"}
                    dangerouslySetInnerHTML={
                      checkIfHtml && parseHTML(blog?.blog_category_name)
                    }
                  ></Box>
                </Box>

              </Box>
              {blog?.title && checkIfHtml(blog?.title) ? (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div
                    className="blog-title"
                    dangerouslySetInnerHTML={
                      checkIfHtml && parseHTML(blog?.title)
                    }
                  ></div>
                </Box>
              ) : (
                <Box
                  marginBottom={"10px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    fontSize={{ xs: "24px", sm: "40px" }}
                    fontWeight={700}
                    lineHeight={{ xs: "38.4px", sm: "64px" }}
                    color="#141621"
                  >
                    {blog?.title}
                  </Typography>
                  {/* <Box
                  bgcolor={"#576edb"}
                  display={"inline-flex"}
                  alignItems={"center"}
                  padding={"5px 10px"}
                  borderRadius={"30px"}
                  letterSpacing={"0.5px"}
                  fontSize={"14px"}
                  color={"#fff"}
                  fontWeight={700}
                >
                  {blog?.category}
                </Box> */}
                </Box>
              )}

              {blog?.description && checkIfHtml(blog?.description) ? (
                <div
                  className="blog-description"
                  dangerouslySetInnerHTML={
                    checkIfHtml &&
                    parseHTML(truncateDescription(blog?.description))
                  }
                ></div>
              ) : (
                <Box>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24.4px"}
                    color="#141621"
                  >
                    {blog?.description}
                  </Typography>
                </Box>
              )}
              <Box
              display={"inline-flex"}
              padding={"2px 8px"}
              style={{
                background:
                  "linear-gradient(180deg, #576EDB 0%, #8957DB 100%)",
              }}
            >
              <Typography fontSize={"14px"} fontWeight={400} color={"#fff"}>
                {blog?.tag}
              </Typography>
            </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            marginY={{ sm: "0", md: "60px" }}
            pr={{ md: "30px" }}
          >
            <Box as="section" padding={{ xs: "20px", sm: "20px", md: 0 }}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  fontSize={{ xs: "20px", sm: "24px" }}
                  fontWeight={700}
                  color={"#141621"}
                >
                  Recent Posts
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                marginTop={{ xs: "10px" }}
                maxHeight={{ sm: "auto", md: "100vh" }}
                overflow={{ sm: "auto", md: "auto" }}
                style={{ scrollbarWidth: "none" }}
              >
                {blogs?.map((blog) => {
                  const data = blog?.attributes;
                  return (
                    <Grid item xs={12} sm={6} md={12} lg={12}>
                      <BlogCard
                        id={blog?.id}
                        image={data?.photo?.url}
                        tag={data?.tag}
                        date={data?.created_at}
                        category={data?.blog_category_name || "N/A"}
                        title={data?.title}
                        description={data?.description}
                        slug={data?.slug}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* <Box
        as="section"
        width={"100%"}
        // height={{ xs: "160px", sm: "300px" }}
        mt={"60px"}
      >
        <img
          src={blog?.image}
          alt="blog_image"
          style={{
            width: "100%",
            maxHeight: "500px",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Box>
      <Box as="section" padding={{ xs: "25px", sm: "80px" }}>
        <Box>
          <Box display={"flex"} alignItems={"center"}>
            <Clock />
            <Typography
              fontSize={{ xs: "12px", sm: "14px" }}
              fontWeight={400}
              color={"#A1A7BC"}
              ml={1}
            >
              
              {moment(blog?.date).format("DD MMMM YYYY")}
            </Typography>
          </Box>
        </Box>
        {blog?.title && checkIfHtml(blog?.title) ? (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <div
              className="blog-title"
              dangerouslySetInnerHTML={parseHTML(blog?.title)}
            ></div>
            <Box
              bgcolor={"#576edb"}
              display={"inline-flex"}
              alignItems={"center"}
              padding={"5px 10px"}
              borderRadius={"30px"}
              letterSpacing={"0.5px"}
              fontSize={"14px"}
              color={"#fff"}
              fontWeight={700}
              dangerouslySetInnerHTML={parseHTML(blog?.category)}
            ></Box>
          </Box>
        ) : (
          <Box
            marginBottom={"10px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              fontSize={{ xs: "24px", sm: "40px" }}
              fontWeight={700}
              lineHeight={{ xs: "38.4px", sm: "64px" }}
              color="#141621"
            >
              {blog?.title}
            </Typography>
            <Box
              bgcolor={"#576edb"}
              display={"inline-flex"}
              alignItems={"center"}
              padding={"5px 10px"}
              borderRadius={"30px"}
              letterSpacing={"0.5px"}
              fontSize={"14px"}
              color={"#fff"}
              fontWeight={700}
            >
              {blog?.category}
            </Box>
          </Box>
        )}

        {blog?.description && checkIfHtml(blog?.description) ? (
          <div
            className="blog-description"
            dangerouslySetInnerHTML={parseHTML(
              truncateDescription(blog?.description)
            )}
          ></div>
        ) : (
          <Box>
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              lineHeight={"24.4px"}
              color="#141621"
            >
              {blog?.description}
            </Typography>
          </Box>
        )}
        <Box
          height={"1px"}
          width={"100%"}
          bgcolor={"#A1A7BC"}
          my={{ xs: "30px", sm: "50px" }}
        ></Box>

        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography
            fontSize={{ xs: "20px", sm: "24px" }}
            fontWeight={700}
            color={"#141621"}
          >
            Recent Posts
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          paddingY={"20px"}
          marginTop={{ xs: "10px" }}
        >
          {blogs?.map((blog) => {
            const data = blog?.attributes;
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <BlogCard
                  id={blog?.id}
                  image={data?.photo?.url}
                  tag={data?.tag}
                  date={data?.created_at}
                  category={data?.blog_category_name || "N/A"}
                  title={data?.title}
                  description={data?.description}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box> */}
      </Layout>
    </>
  );
};

export default BlogDetails;
