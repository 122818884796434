import {
  ALLOTTED_JOBS,
  CANDIDATE,
  CANDIDATE_PROFILE,
  CHANGE_PASSWORD,
  COMMUNICATION_DETAILS,
  COMPANY_DETAILS,
  EARNING,
  EMPLOYER,
  FORGOT_PASSWORD,
  FREELANCER,
  FREELANCER_JOB_DETAILS,
  FREELANCER_OTHER_DETAILS,
  FREELANCER_PERSONAL_DETAILS,
  HIRING_ASSISTANCE,
  JOB_DETAILS,
  JOB_OPENINGS,
  JOB_SEEKER,
  JOB_STATUS,
  OTHER_DETAILS,
  OTP_VERIFICATION,
  OVERVIEW,
  PERSONAL_DETAILS,
  SETTING,
  SIGNUP,
  SIGNUP_PROCEED,
  SEARCH,
  LOGIN,
  RESET_PASSWORD,
  OPEN_POSITIONS,
  PAST_OPENINGS,
  ALL_JOBS,
  JOB_TOPHIRING,
  PRIVACY_POLICY,
  TERMS_CONDITION,
  HELP_SUPPORT,
  BLOGS,
  BLOGS_WITH_ID,
  CONTACT_US,
} from "./constants";
// components
import Dashboard from "../pages/Employer/Dashboard";
import HiringAssistance from "../pages/Employer/HiringAssist";
import JobDetails from "../pages/Employer/JobDetails/JobDetails";
import Setting from "../pages/Employer/Settings/Setting";
import JobSeekerDashboard from "../pages/JobSeeker/Dashboard/Dashboard";
import Portal2JobDetails from "../pages/JobSeeker/Jobs/JobDetails";
import Portal2JobPosting from "../pages/JobSeeker/Jobs/JobPosting";
import Portal2JobStatus from "../pages/JobSeeker/Jobs/JobStatus";
import FreelancerDashboard from "../pages/Freelancer/Dashboard";
import Candidates from "../pages/Freelancer/Candidates/Candidates";
import Earning from "../pages/Freelancer/Earning/Earning";
import AllotedJobs from "../pages/Freelancer/AllottedJobs/AllottedJobs";
import FreelancerJobDetails from "../pages/Freelancer/JobDetails/JobDetails";
import CommunicationDetails from "../pages/Profile/CommunicationDetails";
import CompanyDetails from "../pages/Profile/CompanyDetails";
import ChangePassword from "../pages/Profile/ChangePassword";
import PersonalDetails from "../pages/Profile/PersonalDetails";
import OtherDetails from "../pages/Profile/OtherDetails";
import CandidateProfile from "../pages/Freelancer/Candidates/Profile/Profile";
import FreelancerPersonal from "../pages/Profile/Freelancer/PersonalDetails";
import FreelnacerOther from "../pages/Profile/Freelancer/OtherDetails";
import Search from "../pages/Search/Search";
import NewOpenings from "../pages/Employer/JobOpenings/NewOpenings";
import OpeningPostions from "../pages/Employer/JobOpenings/OpeningPostions";
import PastOpenings from "../pages/Employer/JobOpenings/PastOpenings";
import PageNotFound from "../components/NotFound/PageNotFound";
import Login from "../pages/Login/Login";
import OtpVerification from "../pages/OtpVerification/OtpVerification";
import ForgotWithEmail from "../pages/ForgotPassword/ForgotWithEmail";
import ForgotConfirmPassword from "../pages/ForgotPassword/ForgotConfirmPassword";
import SignupPrimary from "../pages/Signup/SignupForm";
import CreateAccount from "../pages/Signup/CreateAccountForm";
import VerifyEmailNumberOtp from "../pages/OtpVerification/VerifyEmailNumberOtp";
import TopHiringJobs from "../pages/JobSeeker/Jobs/TopHiringJobs";
import CandidateDetailsOverview from "../pages/Employer/Overview/CandidateDetails";
import ReferredCandidates from "../pages/Freelancer/AllottedJobs/ReferredCandidates";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndCondition from "../pages/TermsAndCondition";
import HelpAndSupport from "../pages/helpAndSupport";
import FrequentlyAskedQuestions from "../pages/FAQ";
import LandingPage from "../landingPage/pages/LandingPage";
import HRPolicy from "../landingPage/pages/hrPolicy";
import JobDescription from "../landingPage/pages/JobDescription";
import SearchJobs from "../landingPage/pages/SearchJobs";
import ContactUs from "../pages/contactUs";
import GlobalJobDetails from "../pages/JobSeeker/Jobs/GlobalJobDetails";
import AuthPage from "../pages/Auth/GoogleFbAuth";
import Employer from "../landingPage/pages/employerLandingPage/Employer";
import Freelancer from "../landingPage/pages/freelancerLandingPage/Freelancer";
import Blog from "../landingPage/pages/blog/Blog";
import BlogDetails from "../landingPage/pages/blogDetails/BlogDetails";

export const routes = [
  // Initial signup or Root Routes definition
  {
    routeType: "isPublicRoute",
    childRoutes: [{ path: `/${SIGNUP}`, element: <SignupPrimary /> }],
  },
  {
    routeType: "",
    childRoutes: [
      { path: `/employers`, element: <Employer /> },
      { path: `/freelancers`, element: <Freelancer /> },
      {
        path: `/faq`,
        element: <FrequentlyAskedQuestions />,
      },
      { path: `/jobseekers`, element: <LandingPage /> },
    ],
  },

  //  this would be a private route
  {
    childRoutes: [{ path: `/${SIGNUP_PROCEED}`, element: <CreateAccount /> }],
  },

  // Initial login or Root Routes definition

  {
    routeType: "",
    childRoutes: [{ path: "/", element: <LandingPage /> }],
  },

  {
    routeType: "isPublicRoute",
    childRoutes: [{ path: `/${LOGIN}`, element: <Login /> }],
  },

  {
    routeType: "",
    childRoutes: [
      { path: `/${OTP_VERIFICATION}`, element: <OtpVerification /> },
      { path: `/${FORGOT_PASSWORD}`, element: <ForgotWithEmail /> },
      { path: `/${RESET_PASSWORD}`, element: <ForgotConfirmPassword /> },
    ],
  },

  {
    routeType: "",
    childRoutes: [
      {
        path: `/frequently-asked/:user_type?`,
        element: <FrequentlyAskedQuestions />,
      },
    ],
  },

  {
    childRoutes: [
      { path: "/two-factor-verification", element: <VerifyEmailNumberOtp /> },
    ],
  },

  // --------------- landing page start -------------
  {
    path: "/",
    hasIndex: true,
    routeType: "",
    childRoutes: [{ index: true, element: <LandingPage /> }],
  },

  {
    routeType: "",
    childRoutes: [
      { path: `/${CONTACT_US}`, element: <ContactUs /> },
      { path: `/${HELP_SUPPORT}`, element: <HelpAndSupport /> },
      { path: `${BLOGS}`, element: <Blog /> },
      { path: `${BLOGS_WITH_ID}`, element: <BlogDetails /> },
      { path: `/${PRIVACY_POLICY}`, element: <PrivacyPolicy /> },
      { path: `/${TERMS_CONDITION}`, element: <TermsAndCondition /> },
    ],
  },

  {
    path: "/hr-policy",
    hasIndex: true,
    routeType: "",
    childRoutes: [{ index: true, element: <HRPolicy /> }],
  },
  {
    path: "/job-description",
    hasIndex: true,
    routeType: "",
    childRoutes: [{ index: true, element: <JobDescription /> }],
  },
  {
    path: "/search-jobs",
    hasIndex: true,
    routeType: "",
    childRoutes: [{ index: true, element: <SearchJobs /> }],
  },
  {
    path: "/search-jobs/jobDetails/:id",
    hasIndex: true,
    routeType: "",
    childRoutes: [{ index: true, element: <GlobalJobDetails /> }],
  },

  // --------------- landing page end ---------------

  // Employer routes =====

  {
    childRoutes: [
      { path: `/${EMPLOYER}`, element: <Dashboard /> },
      { path: `/${SETTING}`, element: <Setting /> },
      { path: `/${HIRING_ASSISTANCE}`, element: <HiringAssistance /> },
      // Job positions routes
      { path: `/${OPEN_POSITIONS}`, element: <OpeningPostions /> },
      { path: `/${PAST_OPENINGS}`, element: <PastOpenings /> },
    ],
  },

  // Employer Job openings routes

  {
    path: `/${JOB_OPENINGS}`,
    hasIndex: true,
    childRoutes: [
      // Job openings routes
      { index: true, element: <NewOpenings /> },
      { path: `:id`, element: <NewOpenings /> },
    ],
  },

  // Employer Job details routes

  {
    path: `/${JOB_OPENINGS}/${JOB_DETAILS}`,
    hasIndex: true,
    childRoutes: [
      // Job details routes
      { index: true, element: <PageNotFound /> },
      { path: `:id`, element: <JobDetails /> },
    ],
  },

  {
    childRoutes: [
      {
        path: `/${JOB_OPENINGS}/${OVERVIEW}/:jobSeekerID`,
        element: <CandidateDetailsOverview />,
      },
    ],
  },

  // Job Seeker routes =====
  {
    childRoutes: [
      { path: `/${JOB_SEEKER}`, element: <JobSeekerDashboard /> },
      { path: `/${ALL_JOBS}`, element: <Portal2JobPosting /> },
      {
        path: `/${ALL_JOBS}/${JOB_DETAILS}/:id`,
        element: <Portal2JobDetails />,
      },
      {
        path: `/top-hiring/${JOB_DETAILS}/:id`,
        element: <Portal2JobDetails />,
      },
      { path: `/${JOB_STATUS}`, element: <Portal2JobStatus /> },
      { path: `/${JOB_TOPHIRING}`, element: <TopHiringJobs /> },
    ],
  },

  // Freelancer routes =====
  {
    childRoutes: [
      { path: `/${FREELANCER}`, element: <FreelancerDashboard /> },
      { path: `/${CANDIDATE}`, element: <Candidates /> },
      { path: `/${ALLOTTED_JOBS}`, element: <AllotedJobs /> },
      { path: `/referred-candidates`, element: <ReferredCandidates /> },
      { path: `/${EARNING}`, element: <Earning /> },
      {
        path: `/${ALLOTTED_JOBS}/${FREELANCER_JOB_DETAILS}`,
        element: <FreelancerJobDetails />,
      },
      {
        path: `/${ALLOTTED_JOBS}/${FREELANCER_JOB_DETAILS}/:id`,
        element: <FreelancerJobDetails />,
      },
      {
        path: `/${CANDIDATE}/${CANDIDATE_PROFILE}`,
        element: <CandidateProfile />,
      },
    ],
  },

  // profile routes
  {
    childRoutes: [
      { path: `/${COMMUNICATION_DETAILS}`, element: <CommunicationDetails /> },
      { path: `/${COMPANY_DETAILS}`, element: <CompanyDetails /> },

      { path: `/${PERSONAL_DETAILS}`, element: <PersonalDetails /> },
      { path: `/${OTHER_DETAILS}`, element: <OtherDetails /> },

      { path: `/${CHANGE_PASSWORD}`, element: <ChangePassword /> },

      {
        path: `/${FREELANCER_PERSONAL_DETAILS}`,
        element: <FreelancerPersonal />,
      },
      { path: `/${FREELANCER_OTHER_DETAILS}`, element: <FreelnacerOther /> },
    ],
  },

  // search routes
  {
    childRoutes: [{ path: `/${SEARCH}`, element: <Search /> }],
  },

  {
    routeType: "",
    childRoutes: [{ path: `/auth-callback?`, element: <AuthPage /> }],
  },
];
