import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import TertiaryWrapper from "../../../components/Wrappers/TertiaryWrapper";
import "./index.scss";
import JobSeekerLayout from "../../Layouts/JobSeeker/JobSeekerLayout";
import {
  getAllActiveJobs,
  setAllActiveJobs,
  setJobsPage,
  setJobsRowCount,
  setJobsSortFilter,
} from "../../../redux/JobSeeker/jobSeekerJobsSlice";
import { useDispatch, useSelector } from "react-redux";
import JobPostCard from "../../../components/JobPostBox/JobPostCard";
import NoJobsFound from "../../../components/NotFound/NoJobsFound";
import { Pagination } from "@mui/material";
import FilterMenu from "../../Employer/FilterMenu";
import { SortMenuItems } from "../../../Data";
import { setIsLoading } from "../../../redux/globalSlice";
import GlobalLoader from "../../../components/Loader/GlobalLoader";

const Portal2JobPosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const { JobsPage, JobsRowCount, JobsSortFilter } = useSelector((state) => state.appliedJobs);
  const { isLoading } = useSelector(state => state.app);
  const allActiveJobs = useSelector((state) => state.appliedJobs.allActiveJobs);
  const handleNavigate = (id) => {
    navigate(`/all-jobs/job-details/${id}`, {
      // replace: true,
    });
  };


  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };


  const handleSortClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  useEffect(() => {
    return () => {
      dispatch(setJobsPage(1));
      dispatch(setJobsRowCount(null));
      dispatch(setJobsSortFilter("asc"))
    }
  }, [])

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getAllActiveJobs())
      .then((res) => {
        if (res.type === "getAllActiveJobs/fulfilled") {
          dispatch(setAllActiveJobs(res?.payload?.data));
          dispatch(setIsLoading(false));
          dispatch(setJobsRowCount(res?.payload?.meta?.total_pages));
        } else if (res.type === "getAllActiveJobs/fulfilled") {
          dispatch(setIsLoading(false));
        }
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
        console.log(e);
      });
  }, [JobsPage, JobsSortFilter]);

  const handlePageChange = (event, newPage) => {
    dispatch(setJobsPage(newPage));
  };
  const handleSortFilter = (param) => {
    dispatch(setJobsPage(1));
    dispatch(setJobsRowCount(null));
    dispatch(setJobsSortFilter(param));
    handleClose();
  }

  return (
    <>
      <JobSeekerLayout>
        <Box>
          <TertiaryWrapper handleSortClick={handleSortClick} showSortOption={true} content={"All Jobs"}>
            {
              isLoading ? <GlobalLoader minHeight="300px" /> : (
                <Grid container spacing={1}>
                  {allActiveJobs.length > 0 ?
                    allActiveJobs.map((elem, idx) => {
                      return (
                        <>
                          <Grid item className="cards-responsive-jobseeker-1400" key={idx} xs={12} md={12} lg={6}>
                            <JobPostCard
                              size={'medium-100'}
                              data={elem.attributes}
                              handleNavigate={() => handleNavigate(elem?.id)}
                              tags={elem.attributes.skills.length > 0}
                            />
                          </Grid>
                        </>
                      );
                    }) : (
                      <Box sx={{ width: "100%", minHeight: "200px" }}>
                        <NoJobsFound height={"450px"} value="No jobs found" />
                      </Box>
                    )}
                </Grid>
              )
            }
            {allActiveJobs.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Pagination
                  count={JobsRowCount}
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Box>
            )}
          </TertiaryWrapper>
        </Box>
      </JobSeekerLayout>
      <FilterMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        menuItems={SortMenuItems}
        filter={JobsSortFilter}
        handleFilter={handleSortFilter}
      />
    </>
  );
};

export default Portal2JobPosting;
