import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";

//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
// OTHER
import PrimaryLabel from "../../../components/Labels/PrimaryLabel";
import MuiTextField from "../../../mui/TextField/MuiTextField";
import MuiTextFieldSelect from "../../../mui/TextField/MuiTextFieldSelect";
import MuiDeletableChip from "../../../mui/Chip/MuiDeletableChip";
import MuiTextArea from "../../../mui/TextArea/MuiTextArea";
import MuiButtonContained from "../../../mui/Buttons/MuiButtonContained";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import ClearIcon from "@mui/icons-material/Clear";
// CSS
import "./index.scss";
import FieldError from "../../../components/Errors/FieldError";
import { NewOpeningSchema } from "../../../schemas/NewOpeningSchema";
import CustomLoadingButton from "../../../components/Loader/LoadingButton";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../../../components/Toaster/Toaster";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAllLists,
  createJobPost,
  getJobPost,
  getJobSkills,
  resetNewOpeningsData,
  setEditData,
  setLocationsListState,
  setNewOpeningsData,
  setSkillsListState,
  showJobPost,
  updateJobPost,
} from "../../../redux/Employer/JobPostSlice";
import { useParams } from "react-router-dom";
import JobOpeningsTabs from "./JobOpeningTabs";
import EmployerLayout from "../../Layouts/Employer/EmployerLayout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getSkillsData,
  getLocationData,
  getCategoriesData,
  getEducationsData,
  setSkills,
  setLocations,
  setCategories,
  setEducations,
} from "../../../redux/JobSeeker/filterApis";
import MuiSelect from "../../../mui/TextField/MuiSelect";
import { returnSubstring } from "../../../utils";
import { ctcArr, experienceArr, modeArr } from "../../../Data";
import MuiSelectWithSearch from "../../../mui/TextField/MuiSelectWithSearch";
import { getDesignationsList } from "../../../redux/JobSeeker/PersonalDetailSlice";
import ReactSelect from "../../../reactSelect/ReactSelect";

const NewOpenings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const job_id = params.id;
  const prefilledDta = useSelector((state) => state?.jobPost?.editJobPost);
  const initialData = useSelector((state) => state?.jobPost?.newOpeningsData);
  const { openSkillsList, openLocationsList } = useSelector(
    (state) => state?.jobPost?.popupOpenStates
  );
  const { categories, skills, locations, educations } = useSelector(
    (state) => state.filterData
  );
  const [designationList, setDesignationList] = useState([]);

  const getSkills = () => {
    dispatch(getSkillsData())
      .then((res) => {
        if (res.type === "getSkillsData/fulfilled") {
          const Data = res.payload.data || [];
          const skills = Data.map((elem) => {
            return {
              id: elem.id,
              label: elem.attributes.name,
              value: elem.attributes.id,
            };
          });
          dispatch(setSkills(skills));
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getDesignations = () => {
    dispatch(getDesignationsList())
      .then((res) => {
        if (res.type === "getDesignationsList/fulfilled") {
          const data = res?.payload?.data?.map((elem) => {
            return {
              value: elem,
              label: elem,
            };
          });
          setDesignationList(data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getDesignations();
  }, []);

  const getEducations = () => {
    dispatch(getEducationsData())
      .then((res) => {
        if (res.type === "getEducationsData/fulfilled") {
          const Data = res.payload.data || [];
          const educations = Data.map((elem) => {
            return {
              id: elem.id,
              label: elem.attributes.education_name,
              value: elem.attributes.id,
            };
          });
          dispatch(setEducations(educations));
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getCategories = () => {
    dispatch(getCategoriesData())
      .then((res) => {
        if (res.type === "getCategoriesData/fulfilled") {
          const Data = res.payload.data || [];
          const categories = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.name,
              value: cat.attributes.id,
            };
          });
          // setCategories(categories);
          dispatch(setCategories(categories));
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getLocation = () => {
    dispatch(getLocationData())
      .then((res) => {
        if (res.type === "getLocationData/fulfilled") {
          const Data = res.payload.data || [];
          const locations = Data?.map((elem) => {
            return {
              id: elem.id,
              label: elem.attributes.name,
              value: elem.attributes.id,
            };
          });
          // setLocations(locations);
          dispatch(setLocations(locations));
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const ChildGrid = ({ children, size = "primary" }) => {
    let gridSize = {};
    switch (size) {
      case "primary":
        gridSize = { xs: 12, md: 12, lg: 12 };
        break;
      case "secondary":
        gridSize = { xs: 12, md: 6, lg: 8 };
        break;
      case "tertiary":
        gridSize = { xs: 12, md: 6, lg: 4 };
        break;
      case "special":
        gridSize = { xs: 12, md: 12, lg: 8 };
        break;
      default:
        gridSize = { xs: 12, md: 12, lg: 12 }; // Default to primary size
    }

    return (
      <Grid className={`oh-form-field form-field-${size}`} item {...gridSize}>
        {children}
      </Grid>
    );
  };

  useEffect(() => {
    if (job_id) {
      dispatch(showJobPost(job_id))
        .then((res) => {
          if (res.type === "showJobPost/fulfilled") {
            dispatch(setEditData(res.payload.data.attributes));
          } else if (res.type === "showJobPost/rejected") {
            navigate("/open-positions");
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } else {
      dispatch(setEditData(null));
      dispatch(resetNewOpeningsData());
    }

    getSkills();
    getLocation();
    getCategories();
    getEducations();
    getDesignations();
  }, [job_id]);

  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (prefilledDta && job_id) {
      let data = {
        title: prefilledDta?.job_title,
        vacancies: parseInt(prefilledDta?.no_of_vacancies),
        experience: experienceArr.find(
          (item) => item.label === prefilledDta?.work_experience
        )?.value,
        ctc: ctcArr.find((elem) => elem.label === prefilledDta?.ctc)?.value,
        mode: modeArr.find((elem) => elem.label === prefilledDta?.work_mode)
          ?.value,
        location: prefilledDta?.locations?.map((elem) => {
          return {
            id: String(elem.id),
            label: elem.name,
            value: elem.id,
          };
        }),
        description: isJSON(prefilledDta?.job_description)
          ? JSON.parse(prefilledDta?.job_description)
          : prefilledDta?.job_description?.replace(/^"|"$/g, "")?.trim(),
        skills: prefilledDta?.skills?.map((elem) => {
          return {
            id: String(elem.id),
            label: elem.name,
            value: elem.id,
          };
        }),
        education: prefilledDta?.education?.id,
        industry: prefilledDta?.category?.id,
        designation: prefilledDta?.designation,
      };
      // setInitialData(data);
      dispatch(setNewOpeningsData(data));
      // initialData = data;

      const restLocations = locations?.filter(
        (option) =>
          !initialData?.location?.find(
            (selected) => selected.value === option.value
          )
      );

      const restSkills = skills?.filter(
        (option) =>
          !initialData?.skills?.find(
            (selected) => selected.value === option.value
          )
      );

      // setLocations(restLocations);
      // setSkills(restSkills);
      dispatch(setSkills(restSkills));
      dispatch(setLocations(restLocations));
    }
  }, [prefilledDta]);

  const handleCreateJobPost = (data) => {
    dispatch(createJobPost(data))
      .then((res) => {
        if (res.type === "jobPost/create-job/fulfilled") {
          Toaster.TOAST(res?.payload?.message, "success");
          dispatch(getJobPost());
          navigate(
            "/open-positions"
            // , { replace: true }
          );
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const handleUpdateJobPost = (data) => {
    dispatch(updateJobPost({ data, job_id }))
      .then((res) => {
        if (res.type === "jobPost/update-job/fulfilled") {
          Toaster.TOAST(res?.payload?.message, "success");
          dispatch(getJobPost());
          navigate(
            prefilledDta?.active ? "/open-positions" : "/past-openings"
            // , { replace: true }
          );
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };
  const handleSubmit = async (values, setSubmitting) => {
    const sanitizedDescription = returnSubstring(
      // JSON.stringify(values.description),
      values.description,
      500
    )
      .replace(/\\"/g, "")
      .replace(/&nbsp;|<[^>]*>?/gm, "");

    let data = {
      data: {
        //   this format needs to be changed it is unnecessary to add data inside data instead do this into api declaration
        job_title: values.title,
        no_of_vacancies: values.vacancies,
        work_experience: values.experience,
        ctc: values.ctc,
        category_id: values.industry,
        work_mode: values.mode,
        location_ids: values.location.map((elem) => elem.value),
        job_description: JSON.stringify(values.description),
        short_description: sanitizedDescription,
        skill_ids: values.skills.map((elem) => elem.id),
        designation: values.designation,
        education_id: values.education,
        ...(job_id && { employer_id: job_id }),
      },
    };
    setSubmitting(false);
    if (job_id) {
      handleUpdateJobPost(data);
    } else {
      handleCreateJobPost(data);
    }
  };
  return (
    <>
      <EmployerLayout>
        <JobOpeningsTabs />
        {
          <Formik
            initialValues={initialData}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              setSubmitting(true);
              dispatch(resetNewOpeningsData());
              resetForm();
              handleSubmit(values, setSubmitting);
            }}
            validateOnChange={true}
            validationSchema={NewOpeningSchema}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setErrors,
              resetForm,
              isSubmitting,
            }) => {
              return (
                <Form autoComplete="off">
                  <Box className="oh-new-opening-container">
                    <Grid container>
                      <ChildGrid>
                        <PrimaryLabel
                          labelType="secondary"
                          label="Job Title"
                          required={true}
                        />
                        <MuiTextField
                          id="title"
                          type="text"
                          placeholder="Eg: Sr. Java Developer"
                          name="title"
                          value={values.title}
                          // validateOnChange={true}
                          onChange={(e) => {
                            (values.title || e.target.value !== "") &&
                              setFieldValue("title", e.target.value);
                          }}
                          error={touched.title && Boolean(errors.title)}
                          helperText={
                            <FieldError
                              errorString={touched.title && errors.title}
                            />
                          }
                        />
                      </ChildGrid>
                      <ChildGrid size="tertiary">
                        <PrimaryLabel
                          labelType="secondary"
                          label="Number of Vacancies"
                          required={true}
                        />

                        <MuiTextField
                          id="vacancies"
                          type="number"
                          placeholder={"Eg: 5"}
                          name="vacancies"
                          value={values.vacancies}
                          onChange={(e) => {
                            setFieldValue("vacancies", e.target.value);
                          }}
                          InputProps={{
                            maxLength: 6,
                            inputProps: {
                              max: 10000,
                              min: 1,
                              isInteger: true,
                            },
                          }}
                          error={touched.vacancies && Boolean(errors.vacancies)}
                          helperText={
                            <FieldError
                              errorString={
                                touched.vacancies && errors.vacancies
                              }
                            />
                          }
                        />
                      </ChildGrid>
                      <ChildGrid size="tertiary">
                        <PrimaryLabel
                          labelType="secondary"
                          label="Work Experience"
                          required={true}
                        />
                        <MuiSelect
                          id="experience"
                          select
                          placeholder={
                            !values.experience &&
                            values.experience !== 0 &&
                            "Eg: 3 yrs"
                          }
                          name="experience"
                          value={values.experience}
                          handleChange={(e) => {
                            setFieldValue("experience", e.target.value);
                          }}
                          selectOptions={experienceArr}
                          error={
                            touched.experience && Boolean(errors.experience)
                          }
                          helperText={
                            <FieldError
                              errorString={
                                touched.experience && errors.experience
                              }
                            />
                          }
                        />
                      </ChildGrid>
                      <ChildGrid size="tertiary">
                        <PrimaryLabel
                          labelType="secondary"
                          label="CTC"
                          required={true}
                        />
                        <MuiSelect
                          id="ctc"
                          select
                          placeholder={
                            !values.ctc &&
                            values.ctc !== 0 &&
                            "Eg: 8 - 11 Lacs P.A."
                          }
                          name="ctc"
                          value={values.ctc}
                          handleChange={(e) => {
                            setFieldValue("ctc", e.target.value);
                          }}
                          selectOptions={ctcArr}
                          error={touched.ctc && Boolean(errors.ctc)}
                          helperText={
                            <FieldError
                              errorString={touched.ctc && errors.ctc}
                            />
                          }
                        />
                      </ChildGrid>

                      <ChildGrid size="tertiary">
                        <PrimaryLabel
                          required={true}
                          labelType="secondary"
                          label="Industry"
                        />
                        <MuiSelect
                          id="industry"
                          select
                          placeholder={
                            !values.industry &&
                            values.industry !== 0 &&
                            "Eg: IT"
                          }
                          name="industry"
                          value={values.industry}
                          handleChange={(e) => {
                            setFieldValue("industry", e.target.value);
                          }}
                          selectOptions={categories}
                          error={touched.industry && Boolean(errors.industry)}
                          helperText={
                            <FieldError
                              errorString={touched.industry && errors.industry}
                            />
                          }
                        />
                      </ChildGrid>
                      <ChildGrid size="tertiary">
                        <PrimaryLabel
                          labelType="secondary"
                          label="Working Mode"
                          required={true}
                        />
                        <MuiSelect
                          id="mode"
                          select
                          placeholder={
                            !values.mode &&
                            values.mode !== 0 &&
                            "Eg: Work From Office"
                          }
                          name="mode"
                          value={values.mode}
                          handleChange={(e) => {
                            setFieldValue("mode", e.target.value);
                          }}
                          selectOptions={modeArr}
                          error={touched.mode && Boolean(errors.mode)}
                          helperText={
                            <FieldError
                              errorString={touched.mode && errors.mode}
                            />
                          }
                        />
                      </ChildGrid>
                      <ChildGrid size="tertiary">
                        <PrimaryLabel
                          labelType="secondary"
                          label="Min Education Required"
                          required={true}
                        />
                        <MuiSelect
                          id="education"
                          select
                          placeholder={
                            !values.education &&
                            values.education !== 0 &&
                            "Eg : B.Tech"
                          }
                          name="education"
                          value={values.education}
                          handleChange={(e) => {
                            setFieldValue("education", e.target.value);
                          }}
                          selectOptions={educations}
                          error={touched.education && Boolean(errors.education)}
                          helperText={
                            <FieldError
                              errorString={
                                touched.education && errors.education
                              }
                            />
                          }
                        />
                      </ChildGrid>
                      <ChildGrid required={true} label="Designation">
                        <PrimaryLabel
                          labelType="secondary"
                          label="Designation"
                          required={true}
                        />
                        <MuiSelectWithSearch
                          id="designation"
                          placeholder={
                            !values.designation &&
                            values.designation !== 0 &&
                            "Eg: CEO"
                          }
                          select
                          name="designation"
                          value={values.designation}
                          handleChange={(val) => {
                            setFieldValue("designation", val.label);
                          }}
                          error={
                            touched.designation && Boolean(errors.designation)
                          }
                          helperText={
                            <FieldError
                              errorString={
                                touched.designation && errors.designation
                              }
                            />
                          }
                          selectOptions={designationList}
                          // props={{
                          //   onKeyPressCapture: () => handleKeyPress,
                          // }}
                        />
                      </ChildGrid>
                      <ChildGrid size="primary">
                        <PrimaryLabel
                          labelType="secondary"
                          label="Key Skills"
                          required={true}
                        />
                        <ReactSelect
                          name="skills"
                          options={skills}
                          placeholder={"Eg: HTML"}
                          value={values?.skills || []}
                          onChange={(e, newValue) => {
                            if (newValue?.action === "select-option") {
                              const updatedSkills = [
                                ...values?.skills,
                                newValue?.option,
                              ];
                              setFieldValue("skills", updatedSkills);
                            } else if (
                              newValue?.action === "remove-value" ||
                              newValue?.action === "pop-value"
                            ) {
                              const updatedSkills = values?.skills.filter(
                                (elem) => elem !== newValue?.removedValue
                              );
                              setFieldValue("skills", updatedSkills);
                            } else {
                              setFieldValue("skills", []);
                            }
                          }}
                        />
                        <FieldError
                          errorString={touched.skills && errors.skills}
                        />
                      </ChildGrid>
                      <ChildGrid size="primary">
                        <PrimaryLabel
                          labelType="secondary"
                          label="Location"
                          required={true}
                        />
                        <Autocomplete
                          // disableCloseOnSelect
                          filterSelectedOptions
                          filterOptions={(locations) =>
                            locations?.filter(
                              (option) =>
                                !values?.location?.find(
                                  (selected) => selected.value === option.value
                                )
                            )
                          }
                          clearIcon={false}
                          options={locations || []}
                          value={values?.location || []}
                          open={openLocationsList}
                          onOpen={() => dispatch(setLocationsListState(true))} // Open the Autocomplete when focused
                          onClose={() => dispatch(closeAllLists(null))}
                          multiple
                          sx={{
                            ".MuiAutocomplete-tag": {
                              // minWidth: "fit-content",
                              padding: "10px",
                              maxWidth: "fit-content",
                              display: "flex",
                              justifyContent: "space-evenly",
                              gap: "5px",
                            },
                            "& .MuiInputBase-root": {
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",
                              height: "auto",
                              minHeight: "40px",
                              maxHeight: "fit-content",
                              padding: "8px",
                              borderRadius: "18px",
                            },
                          }}
                          style={{ maxHeight: "auto" }}
                          noOptionsText={"No options"}
                          renderTags={(value, props) =>
                            value?.map((option, index) => (
                              <MuiDeletableChip
                                sx={{
                                  minWidth: "fit-content",
                                  maxWidth: "150px",
                                }}
                                label={option?.label}
                                {...props({ index })}
                                key={index}
                              />
                            ))
                          }
                          onChange={(e, newValue) => {
                            setFieldValue("location", newValue);
                          }}
                          renderInput={(params) => (
                            <MuiTextField
                              placeholder={
                                values?.location?.length < 1 && "Eg: Indore"
                              }
                              error={
                                touched.location && Boolean(errors.location)
                              }
                              helperText={
                                <FieldError
                                  errorString={
                                    touched.location && errors.location
                                  }
                                />
                              }
                              param={params}
                            />
                          )}
                        />
                      </ChildGrid>
                      <ChildGrid>
                        <PrimaryLabel
                          labelType="secondary"
                          label="Job Description"
                          required={true}
                        />
                        <Field name="description">
                          {({ field }) => (
                            <ReactQuill
                              className="oh-quill-editor"
                              style={{
                                borderRadius: "14px",
                                border:
                                  touched.description &&
                                  Boolean(errors.description) &&
                                  "1px solid red",
                              }}
                              value={field.value}
                              onChange={(value) => {
                                // Formik specific onChange handling
                                field.onChange("description")(value);
                              }}
                            />
                          )}
                        </Field>

                        {touched.description && Boolean(errors.description) && (
                          <FieldError
                            sx={{
                              margin: "5px 12px",
                              span: { fontWeight: 500 },
                            }}
                            errorString={
                              touched.description && errors.description
                            }
                          />
                        )}
                      </ChildGrid>
                    </Grid>
                    <MuiDivider className="oh-details-divider" />
                    <CustomLoadingButton
                      loading={isSubmitting}
                      type="submit"
                      title={"Submit"}
                      className="oh-submit-btn"
                    />
                    <MuiButtonContained
                      variant="outlined"
                      className="oh-cancel-btn"
                      title="Cancel"
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => {
                        resetForm();
                        dispatch(resetNewOpeningsData());
                        navigate(
                          `/open-positions`
                          // , { replace: true }
                        );
                      }}
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        }
      </EmployerLayout>
    </>
  );
};

export default NewOpenings;

// Dummy A
