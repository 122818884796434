import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//MUI
import Box from "@mui/material/Box";

//OTHER
import Sidebar from "../../../components/Sidebar/Sidebar";
import MuiBreadcrumb from "../../../mui/BreadCrumbs/MuiBreadcrumbs";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import JobInfoCard from "../../../components/Cards/JobSeeker/JobInfoCard";

// CSS
import "../layout.scss";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import NotAuthorized from "../../../components/NotFound/NotAuthorized";
import Header from "../../../components/Header/Header";
import {
  getSuggestedJobs,
  setSuggestedJobs,
} from "../../../redux/JobSeeker/jobSeekerJobsSlice";
import NoJobsFound from "../../../components/NotFound/NoJobsFound";
import { Grid } from "@mui/material";

const JobSeekerLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const suggestedJobs = useSelector((state) => state.appliedJobs.suggestedJobs);
  const { portalName } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(true);

  const getSuggestedJobsData = () => {
    const authParams = true;
    dispatch(getSuggestedJobs(authParams))
      .then((res) => {
        if (res.type === "getSuggestedJobs/fulfilled") {
          dispatch(setSuggestedJobs(res?.payload?.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNavigate = (id) => {
    navigate(`/all-jobs/job-details/${id}`);
  };

  useEffect(() => {
    localStorage.removeItem("form_type");
    localStorage.removeItem("auth_via");
    getSuggestedJobsData();

    // Mimic async state fetching/updating preventing default not authorized page to show
    setTimeout(() => {
      setLoading(false);
    }, 10);

  }, []);


  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {portalName === "Jobseeker" ? (
        <>
          <Header />
          <Box container className="oh-jobseeker-background-setting">
            <Sidebar className="primary-navigation-bar" title="Job Seeker" />
            <Box>
              {location.pathname !== "/jobseeker" && <MuiBreadcrumb />}
              {children}
            </Box>

            <Box
              className="oh-cards-section"
              sx={{
                position: "sticky",
                top: "100px",
                display: "flex",
                gap: "18px",
                padding: "0px",
                height: "calc(100vh - 140px)",
                overflow: "auto",
              }}
            >
              <MuiDivider
                className="oh-vertical-divider"
                orientation="vertical"
              />
              <Box
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <MuiTypography
                  content="Jobs you might be interested"
                  className="oh-heading"
                />
                <Grid container className="interested-jobs-holder">
                  {suggestedJobs.length > 0 ?
                    suggestedJobs.map((elem, idx) => {
                      return (
                        <Grid className="interested-jobs-card" marginBottom={0} item xs={12}>
                          <JobInfoCard

                            cardType="suggestedJobs"
                            data={elem.attributes}
                            onClick={() => handleNavigate(elem?.attributes?.id)}
                            onApplyClick={(e) => {
                              e.preventDefault();
                              handleNavigate(elem?.attributes?.id)
                            }
                            }
                            footerText={false}
                            timestamp={true}
                          />
                        </Grid>
                      );
                    })


                    : (
                      <NoJobsFound value={"No jobs at the moment"} height={"80%"} />
                    )}
                </Grid>
              </Box >
            </Box >
          </Box >
        </>
      ) : (
        <NotAuthorized />
      )
      }
    </>
  );
};

export default JobSeekerLayout;
