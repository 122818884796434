import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import globalReducer from "./globalSlice";
import CommunicationDetailReducer from "./Employer/CommunicationDetailSlice";
import forgotPasswordReducer from "./ForgotPasswordSlice";
import verifyOtpReducer from "./verifyOtpSlice";
import resetPasswordReducer from "./resetPasswordSlice";
import jobPostReducer from "./Employer/JobPostSlice";
import hiringAssistanceReducer from "./Employer/HiringAssistanceSlice";
import companyInfoReducer from "./companyInfoSlice";
import googleAuthReducer from "./googleAuthSlice";
import fbAuthReducer from "./fbAuthSlice";
import PersonalDetailReducer from "./JobSeeker/PersonalDetailSlice";
import personalInfoReducer from "./JobSeeker/personalInfoSlice";
import profileReducer from "./profileSlice";
import pincodeReducer from "./pincodeSlice";
import selectionRoundsReducer from "./Employer/SelectionRoundSlice";
import tableDataReducer from "./tableSlice";
import employerSearchReducer from "./Employer/searchSlice";
import allJobsReducer from "./JobSeeker/jobSeekerJobsSlice";
import companyDetailsReducer from "./companyDetailsSlice";
import statsDataReducer from "./statsDataSlice";
import filterDataReducer from "./JobSeeker/filterApis";
import freelancerDetailsReducer from "./Freelancer/freelancerDetailsSlice";
import earningDataReducer from "./Freelancer/earningDetailSlice";
import allotedJobsReducer from "./Freelancer/allotedJobsSlice";
import freelancerActiveCandidatesReducer from "./Freelancer/freelancerActiveCandidatesSlice";
import freelancerPlacedCandidatesReducer from "./Freelancer/freelancerPlacedCandidatesSlice";
import referredCandidatesReducer from "./Freelancer/referredCandidatesSlice";
import globalNotificationsReducer from "./notificationsSlice";
import faqReducer from "./faqSlice";
import privacyReducer from "./policySlice";
import blogsDataReducer from "./blogsSlice";
import SearchReducer from "./LandingPage/LandingPageSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: globalReducer,
    jobPost: jobPostReducer,
    selectionRounds: selectionRoundsReducer,
    hiringAssistance: hiringAssistanceReducer,
    communicationDetail: CommunicationDetailReducer,
    personalDetail: PersonalDetailReducer,
    forgotPassword: forgotPasswordReducer,
    verifyOtp: verifyOtpReducer,
    resetPassword: resetPasswordReducer,
    companyInfo: companyInfoReducer,
    personalInfo: personalInfoReducer,
    googleAuth: googleAuthReducer,
    fbAuth: fbAuthReducer,
    profile: profileReducer,
    pincode: pincodeReducer,
    tableData: tableDataReducer,
    searchForEmployer: employerSearchReducer,
    appliedJobs: allJobsReducer,
    companyDetails: companyDetailsReducer,
    statsData: statsDataReducer,
    filterData: filterDataReducer,
    freelancerDetailData: freelancerDetailsReducer,
    earningData: earningDataReducer,
    allotedJobs: allotedJobsReducer,
    freelancerActiveCandidates: freelancerActiveCandidatesReducer,
    freelancerPlacedCandidates: freelancerPlacedCandidatesReducer,
    referredCandidates: referredCandidatesReducer,
    globalNotifications: globalNotificationsReducer,
    faq: faqReducer,
    privacyPolicyData: privacyReducer,
    blogsData: blogsDataReducer,
    search: SearchReducer,
  },
});
