import React, { useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//MUI
import Box from "@mui/material/Box";
import DownloadIcon from '@mui/icons-material/Download';

//OTHER
import MuiTypography from "../../../../mui/Typography/MuiTypograpghy";
import FreelancerLayout from "../../../Layouts/Freelancer/FreelancerLayout";
import ExtrasTooltip from "../../../../components/Tooltip/ExtrasTooltip";
import { ctcArr, freelancerExperienceArr } from "../../../../Data";
import Skills from "../../../JobSeeker/Jobs/Components/Skills";
import Female from "../../../../assets/images/female.svg";
import Male from "../../../../assets/images/male.svg";
import AirBnbIcon from "../../../../assets/images/Airbnb.svg";
import LocationIcon from "../../../../assets/images/location.svg";
import SuitcaseIcon from "../../../../assets/images/suitcaseIcon.svg";
import CurrencyRupeeIcon from "../../../../assets/images/ruppeeIcon.svg";
import { clearSelectedCandidates } from "../../../../redux/Freelancer/allotedJobsSlice";
import { handleDownload } from "../../../../utils";

//CSS
import "./index.scss";

const CandidateProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const data = location?.state?.data || {};
    const handleViewJob = (status, id) => {
        dispatch(clearSelectedCandidates());
        navigate(`/allotted-jobs/job-details/${id}`, {
            state: { status: status },
            // replace: true,
        })
    }

    const locationsArr = data?.locations?.map((location) => location.name).join(", ");
    // const remainingLocationsCount = data?.locations?.length - 2;
    // const remainingLocations = data?.locations
    //     ?.slice(2)
    //     .map((elem) => elem.name)
    //     .join(", ");

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        showFullDescription && window.scrollTo(0, 0);
        setShowFullDescription(!showFullDescription);
    };

    const returnLocation = (jobData) => {
        const location = jobData?.locations
            ?.slice(0, 2)
            .join(", ");
        const remainingLocationsCount = jobData?.locations?.length - 2;
        const remainingLocations = jobData?.locations?.slice(2).join(", ");
        return { location, remainingLocationsCount, remainingLocations };
    }

    return (
        <FreelancerLayout>
            <Box className="oh-candidate-profile">

                <Box className="oh-candidate-details-overview">
                    <img
                        src={data?.image || data?.gender === "Male" ? Male : Female}
                        alt="candidate"
                        className="candidateImage"
                    />
                    <Box className="oh-candidate-details-section">
                        <Box className="oh-details-header">
                            <Box className="oh-details-box">
                                <MuiTypography
                                    content={data?.first_name ? `${data?.first_name} ${data?.last_name} 123` : "-"}
                                    className="oh-title"
                                />
                                <MuiTypography
                                    content={data?.designation || ""}
                                    className="oh-subtitle"
                                />
                            </Box>
                            {data?.updated_at && (
                                <MuiTypography
                                    content={`Updated: ${moment(data?.updated_at).fromNow()}`}
                                    className="oh-details-posted-text"
                                />
                            )}
                        </Box>

                        <Box className="oh-card-body oh-details-card-body">
                            {data?.experience &&
                                <MuiTypography key={0} variant="span" className="oh-requirements">
                                    <img src={SuitcaseIcon} className="candidate-suitcase-icon" alt={"work-exp"} />{" "}
                                    {
                                        freelancerExperienceArr.find((item) => item.value === data?.experience)
                                            ?.label
                                    }
                                </MuiTypography>
                            }

                            {data?.ctc &&
                                <MuiTypography variant="span" className="oh-requirements">
                                    <img src={CurrencyRupeeIcon} className="candidate-rupee-icon" alt={"salary"} />{" "}
                                    {ctcArr.find((item) => item.value === data?.ctc)?.label}
                                </MuiTypography>
                             } 
                            {locationsArr && (
                                <MuiTypography variant="span" className="oh-requirements">
                                    <img src={LocationIcon} className="candidate-location-icon" alt={"locations"} />
                                    {locationsArr}
                                    {/* {remainingLocationsCount > 0 && (
                                        <ExtrasTooltip
                                            count={remainingLocationsCount}
                                            tooltipText={remainingLocations}
                                        />
                                    )} */}
                                </MuiTypography>
                            )}
                        </Box>
                        {data?.resume?.url ? (
                            <Box
                                sx={{
                                    border: "1px solid var(--oh-white-300-color)",
                                    minWidth: "60%",
                                    background: "var(--oh-white-color)",
                                    maxWidth: "500px",
                                    padding: "10px",
                                    display: "flex",
                                    borderRadius: "4px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        lineHeight: "20.5px",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        color: "#445386",
                                    }}
                                >
                                    <p style={{ letterSpacing: "0.4px" }}>
                                        {" "}
                                        {data?.resume_name || "Resume Doc"}
                                    </p>
                                    {data?.updated_at && <span
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            color: "var(--oh-gray-color)",
                                        }}
                                    >
                                        Uploaded on {moment(data?.updated_at).fromNow()}
                                    </span>}
                                </div>

                                <DownloadIcon
                                    onClick={() => handleDownload(data?.resume?.url, data?.resume_name)}
                                    sx={{
                                        color: "var(--oh-blue-300-color)",
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        padding: "5px",
                                        background: "var(--oh-white-300-color)",
                                        svg: {
                                            fontSize: "20px",
                                        },
                                    }}
                                />
                            </Box>
                        ) : <span
                            style={{
                                fontSize: "12px",
                                fontWeight: 400,
                                fontStyle: "italic",
                                color: "var(--oh-gray-color)",
                            }}
                        >
                            No Resume Uploaded
                        </span>}
                        {data?.skills?.length > 0 && showFullDescription && (
                            <Box style={{ padding: "20px 0 0" }}>
                                <h3 className="oh-details-subheading">Skills</h3>
                                <Skills
                                    size="medium-300"
                                    skillsData={data?.skills.map((elem) => elem.name)}
                                />
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <span
                                className="oh-more-view"
                                onClick={toggleDescription}
                                style={{ cursor: "pointer", color: "var(--oh-strongpink-color)" }}
                            >
                                {showFullDescription ? "View Less" : "View Full Profile"}
                            </span>
                        </Box>
                    </Box>
                </Box>

                {/* status card */}
                {
                    data?.jobs_status?.length > 0 && data?.jobs_status.map((elem) => {
                        let statusClass = elem.status === "Rejected" ? "rejected-status" : elem.status === "Inprocess" ? "under-progress-status" : "oh-status";
                        let statusTopBox = elem.status === "Rejected" ? "status-rejected-box" : elem.status === "Inprocess" ? "status-under-progress-box" : "status-success-box";

                        return (
                            <>
                                <Box className="oh-status-card">
                                    <Box className={`status-detail-box ${statusTopBox}`}>
                                        <Box className="oh-details-card-header">
                                            {/* currently, there is no image attribute in response */}
                                            <img src={elem?.image || AirBnbIcon} alt={"logo"} /> 
                                            <Box className="job-desc-box">
                                                <MuiTypography content={elem?.title} className="oh-title" />
                                                <MuiTypography content={elem?.job_number ? "#" + elem?.job_number : <span
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: 400,
                                                        fontStyle: "italic",
                                                        color: "var(--oh-gray-color)",
                                                    }}
                                                >
                                                    #JOBID not available
                                                </span>} className="oh-subtitle" />
                                            </Box>
                                            {elem?.status && <MuiTypography className={`oh-status-text ${statusClass}`} content={elem?.status} />}
                                        </Box>
                                        <Box className="oh-card-body">
                                            {/* {elem?.status && <MuiTypography className={`oh-status-text ${statusClass}`} content={elem?.status} />} */}

                                            <Box className="oh-requirement-box">
                                                {(elem?.experience || elem?.experience == 0) && <MuiTypography variant="span" className="oh-requirements">
                                                    <img src={SuitcaseIcon} className="candidate-status-suitcase-icon" alt={"work-exp"} /> {elem?.experience}
                                                </MuiTypography>}

                                                {(elem?.ctc || elem?.ctc == 0) && <MuiTypography variant="span" className="oh-requirements">
                                                    <img src={CurrencyRupeeIcon} className="candidate-status-rupee-icon" alt={"salary"} /> {ctcArr?.find(opt => opt.value === elem?.ctc)?.label}
                                                </MuiTypography>}

                                                {returnLocation(elem).location && (
                                                    <MuiTypography variant="span"  className="oh-requirements">
                                                        <img src={LocationIcon} className="candidate-status-location-icon" alt={"locations"} />
                                                        {returnLocation(elem).location}
                                                        {returnLocation(elem).remainingLocationsCount > 0 && (
                                                            <ExtrasTooltip
                                                                count={returnLocation(elem).remainingLocationsCount}
                                                                tooltipText={returnLocation(elem).remainingLocations}
                                                            />
                                                        )}
                                                    </MuiTypography>
                                                )}

                                            </Box>
                                            {/* {data?.created_at && <MuiTypography className="oh-posted-text" content={`Posted: ${data?.created_at}`} />} */}
                                            {<MuiTypography onClick={() => {
                                                dispatch(clearSelectedCandidates());
                                                navigate(`/allotted-jobs/job-details/${elem.id}`)
                                            }}
                                                className="oh-view-job-text" content={"View Job"} />}
                                        </Box>
                                    </Box>
                                    {elem?.reason && <Box sx={{
                                        // background: "red",
                                        margin: "10px 30px 20px",
                                        padding: "10px 15px",
                                        lineHeight: "18px",
                                        borderRadius: "16px",
                                        background: "var(--oh-red-300-color)",
                                        color: "var(--oh-strongpink-color)",
                                        fontSize: "12px",
                                    }}>
                                        {`Rejection Reason - ${elem.reason}`}
                                    </Box>}
                                </Box>
                            </>
                        )
                    })
                }
            </Box >
        </FreelancerLayout>
    )
}

export default CandidateProfile;