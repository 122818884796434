import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Form, Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { toArray } from "lodash";
// MUI
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Circle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Autocomplete, IconButton, Typography } from "@mui/material";

// OTHER
import MuiTypography from "../../mui/Typography/MuiTypograpghy";
import PrimaryLabel from "../../components/Labels/PrimaryLabel";
import MuiDivider from "../../mui/Dividers/MuiDivider";
import MuiTextField from "../../mui/TextField/MuiTextField";
import MuiDeletableChip from "../../mui/Chip/MuiDeletableChip";
import FieldError from "../../components/Errors/FieldError";
import CustomLoadingButton from "../../components/Loader/LoadingButton";
import ProfileLayout from "./ProfileLayout";
import {
  editPersonalDetailApi,
  getPersonalDetailApi,
  setJobseekerDetailsData,
} from "../../redux/JobSeeker/PersonalDetailSlice";
import {
  getUniversityData,
  getCategoriesData,
  getLocationData,
  getSkillsData,
} from "../../redux/JobSeeker/filterApis";
import { Toaster } from "../../components/Toaster/Toaster";
import MuiSelect from "../../mui/TextField/MuiSelect";
import { getYearsData, smoothScroll } from "../../utils";
import { ctcArr, experienceArr, expertiesIn } from "../../Data";
import { errMessageMaximum } from "../../utils";
import { FIRSTNAME_REGEX } from "../../utils/regex";
import { setUserData } from "../../redux/authSlice";
import ReactSelect from "../../reactSelect/ReactSelect";
// CSS
import "./index.scss";
import MuiSelectWithSearch from "../../mui/TextField/MuiSelectWithSearch";

const OtherDetails = () => {
  const navigate = useNavigate();
  const [yearsData, setYearsData] = useState([]);
  const [universityData, setUniversityData] = useState([]);
  const userId = localStorage.getItem("login_data")
    ? JSON.parse(localStorage.getItem("login_data")).id
    : null;
  useEffect(() => {
    const date = new Date();
    let present_year = parseInt(date.getFullYear());
    const yrsData = getYearsData(present_year + 1);
    setYearsData(yrsData);
  }, []);
  const dispatch = useDispatch();
  const initialData = useSelector(state => state.personalDetail.jobseekerOtherDetailsData);
  const { user } = useSelector((state) => state?.auth);

  const OtherDetailSchema = Yup.object().shape({
    educationDetails: Yup.array().of(
      Yup.object().shape({
        year: Yup.string().required("Year is required"),
        university: Yup.string().required("university is required"),
        percentage: Yup.number()
          .required("Percentage is required")
          .min(1, `Percentage must be at atleast greater than 0%`)
          .max(100, `Percentage can't be more than 100%`),
      })
    ),
    experienceDetails: Yup.array().of(
      Yup.object().shape({
        year: Yup.string().required("Year is required"),
        company: Yup.string()
          .required("Current company name is required.")
          .max(150, errMessageMaximum("Current company name", 150))
          .test(
            "check if Current company name not empty",
            "Current company name must be relevant.",
            (val) => {
              if (!val || val.trim().length === 0 || val.trim().length < 2) {
                return false;
              }
              return true;
            }
          )
          .matches(FIRSTNAME_REGEX, "Current company name must be valid."),
        designation: Yup.string()
          .required("Designation is required.")
          .max(60, errMessageMaximum("Designation", 60))
          .test(
            "check if  Designation not empty",
            "Designation must be relevant.",
            (val) => {
              if (!val || val.trim().length === 0 || val.trim().length < 2) {
                return false;
              }
              return true;
            }
          )
          .matches(FIRSTNAME_REGEX, "Designation must be valid."),
      })
    ),
    skills: Yup.array().min(1, "At least one skill is required"),
    categories: Yup.array().min(1, "At least one category is required"),
    locations: Yup.array().min(1, "At least one location is required"),
    ctc: Yup.string().required("CTC is required"),
  });

  const [skillsOpt, setSkillsOpt] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getUniversity();
    getSkills();
    getLocation();
    getCategories();
    showJobseekerDetails();
  }, []);

  const getUniversity = () => {
    dispatch(getUniversityData())
      .then((res) => {
        if (res.type === "getUniversityData/fulfilled") {
          const Data = res.payload.data || [];
          const university = Data?.map((elem) => {
            return {
              label: elem.attributes.name,
              value: elem.attributes.id,
            };
          });
          setUniversityData(university);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getSkills = () => {
    dispatch(getSkillsData())
      .then((res) => {
        if (res.type === "getSkillsData/fulfilled") {
          const Data = res.payload.data || [];
          const skills = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.name,
              value: cat.attributes.id,
            };
          });
          setSkillsOpt(skills);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getCategories = () => {
    dispatch(getCategoriesData())
      .then((res) => {
        if (res.type === "getCategoriesData/fulfilled") {
          const Data = res.payload.data || [];
          const categories = Data.map((cat) => {
            return {
              id: cat.id,
              label: cat.attributes.name,
              value: cat.attributes.id,
            };
          });
          setCategories(categories);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const getLocation = () => {
    dispatch(getLocationData())
      .then((res) => {
        if (res.type === "getLocationData/fulfilled") {
          const Data = res.payload.data || [];
          const locations = Data.map((elem) => {
            return {
              id: elem.id,
              label: elem.attributes.name,
              value: elem.attributes.id,
            };
          });
          setLocations(locations);
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
  };

  const showJobseekerDetails = () => {
    dispatch(getPersonalDetailApi(userId))
      .then((res) => {
        if (res.type === "personal_details/list/fulfilled") {
          const data = res?.payload?.data.attributes.personal_details;
          let educationDetailsData =
            Object.keys(data.education_details)?.length != 0
              ? toArray(data?.education_details)
              : [{ year: null, university: "", percentage: "" }];
          let experienceDetailsData =
            Object.keys(data.experience_details)?.length != 0
              ? toArray(data?.experience_details)
              : [{ year: null, company: "", designation: "" }];
          let profileData = {
            educationDetails: educationDetailsData,
            experienceDetails: experienceDetailsData,
            ctc: data?.ctc,
            ectc: data?.ectc,
            locations: data?.locations?.map((elem) => {
              return {
                id: String(elem.id),
                label: elem.name,
                value: elem.id,
              };
            }),
            experties_in: data?.expertise.map((elem, index) => {
              const data = { id: index, value: elem, label: elem };
              return data;
            }),
            skills: data?.skills?.map((elem) => {
              return {
                id: String(elem.id),
                label: elem.name,
                value: elem.id,
              };
            }),
            categories: data?.categories?.map((elem) => {
              return {
                id: String(elem.id),
                label: elem.name,
                value: elem.id,
              };
            }),
            english: parseInt(data?.languages[0]?.proficiency),
            hindi: parseInt(data?.languages[1]?.proficiency),
          }
          dispatch(setJobseekerDetailsData(profileData));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (values, setSubmitting) => {
    let formData = new FormData();
    values.educationDetails.map((el, index) => {
      formData.append(`data[education_details][${index}][year]`, el.year);
      formData.append(
        `data[education_details][${index}][university]`,
        el.university
      );
      formData.append(
        `data[education_details][${index}][percentage]`,
        el.percentage
      );
    });
    values.experienceDetails.map((el, index) => {
      formData.append(`data[experience_details][${index}][year]`, el.year);
      formData.append(
        `data[experience_details][${index}][company]`,
        el.company
      );
      formData.append(
        `data[experience_details][${index}][designation]`,
        el.designation
      );
    });
    values.skills.map((el, index) => {
      formData.append("data[skill_ids][]", el.value);
    });
    values.categories.map((el, index) => {
      formData.append("data[category_ids][]", el.value);
    });
    values.locations.map((el, index) => {
      formData.append("data[location_ids][]", el.value);
    });
    // NOTE* currently BE is sending "" in response if you send [] on no data selecting causing too many glitches
    //  & EMPTY chipsets or no prefilled data when data present, informed BE team, will be changing once they r done with fixes
    values.experties_in.map((el, index) => {
      formData.append("data[expertise][]", el.value);
    });
    formData.append("data[ctc]", values.ctc);
    formData.append("data[ectc]", values.ectc);
    formData.append("data[languages][][language]", "English");
    formData.append("data[languages][][proficiency]", [values.english]);
    formData.append("data[languages][][language]", "Hindi");
    formData.append("data[languages][][proficiency]", [values.hindi]);
    dispatch(editPersonalDetailApi({ id: user.id, data: formData }))
      .then((res) => {
        if (res.type === "personal_details/edit/fulfilled") {
          smoothScroll();
          Toaster.TOAST(res.payload?.message, "success");
          showJobseekerDetails();
          let user_data = res.payload.data.attributes["jobseeker"];
          user_data = {
            ...user_data,
            personal_details: res.payload.data.attributes.personal_details,
          };

          localStorage.setItem("login_data", JSON.stringify(user_data));
          dispatch(setUserData(user_data));
          if (res.payload.data.attributes["jobseeker"]?.profile_complete) {
            Toaster.TOAST("Profile completed successfully! Please proceed to apply to jobs.")
            navigate("/all-jobs")
          }
        } else if (res.type === "personal_details/edit/rejected") {
          smoothScroll();
        }
      })
      .catch((err) => {
        console.log(err.message, ".err");
      });
    setSubmitting(false);
  };

  const ChildGrid = ({
    children,
    size = "",
    className = "oh-form-field",
    label = null,
    required = false,
  }) => {
    let gridSize = {};
    switch (size) {
      case "primary":
        gridSize = { xs: 12, md: 12, lg: 12 };
        break;
      case "secondary":
        gridSize = { xs: 12, md: 6, lg: 6 };
        break;
      case "secondary_1200":
        gridSize = { xs: 12, md: 12, lg: 6 };
        break;
      case "tertiary":
        gridSize = { xs: 12, md: 1, lg: 1 };
        break;
      case "three_fourth":
        gridSize = { xs: 12, md: 12, lg: 9 };
        break;
      default:
        gridSize = { xs: 12, md: 12, lg: 3 }; // Default to primary size
    }

    return (
      <Grid className={className} item {...gridSize}>
        {label && (
          <PrimaryLabel
            labelType="secondary"
            label={label}
            required={required}
          />
        )}
        {children}
      </Grid>
    );
  };

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#576EDB",
    },
    "& .MuiRating-iconHover": {
      color: "#576EDB",
    },
  });

  return (
    <ProfileLayout>
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        enableReinitialize={true}
        validateOnblur={false}
        validationSchema={OtherDetailSchema}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          errors,
          touched,
          dirty,
        }) => {
          return (
            <Form className="oh-signup-form" autoComplete="off">
              {/* Education Details Section */}
              <Grid container lg={12}>
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Education Details"
                  />
                  <FieldArray name="educationDetails">
                    {(fieldArrayProps) => {
                      const { form, push, remove } = fieldArrayProps;
                      const { values } = form;
                      const { educationDetails } = values;

                      return educationDetails.map((elem, index) => {
                        return (
                          <Grid
                            className="oh-company-details"
                            container
                            key={index}
                          >
                            <ChildGrid required={true} label="Year">
                              <MuiSelect
                                id={educationDetails[index].id}
                                select
                                placeholder={
                                  !educationDetails[index].year &&
                                  educationDetails[index].year != 0 &&
                                  "Select Year"
                                }
                                name={`educationDetails[${index}].year`}
                                value={educationDetails[index].year}
                                selectOptions={yearsData}
                                handleChange={(e) => {
                                  setFieldValue(
                                    `educationDetails[${index}].year`,
                                    e.target.value
                                  );
                                }}
                                error={
                                  touched?.educationDetails?.[index]?.year &&
                                  Boolean(
                                    errors?.educationDetails?.[index]?.year
                                  )
                                }
                                helperText={
                                  <FieldError
                                    errorString={
                                      touched?.educationDetails?.[index]
                                        ?.year &&
                                      errors?.educationDetails?.[index]?.year
                                    }
                                  />
                                }
                              />
                            </ChildGrid>
                            <ChildGrid
                              required={true}
                              label="University/Board Name"
                            >
                              <MuiSelectWithSearch
                                id={educationDetails[index].id}
                                placeholder={
                                  !educationDetails[index].university &&
                                  "Select University"
                                }
                                select
                                name={educationDetails[index].university}
                                value={educationDetails[index].university}
                                handleChange={(val) => {
                                  setFieldValue(
                                    `educationDetails[${index}].university`,
                                    val.label
                                  );
                                }}
                                error={
                                  touched?.educationDetails?.[index]?.university &&
                                  Boolean(
                                    errors?.educationDetails?.[index]?.university
                                  )
                                }
                                helperText={
                                  <FieldError
                                    errorString={
                                      touched?.educationDetails?.[index]
                                        ?.university &&
                                      errors?.educationDetails?.[index]?.university
                                    }
                                  />
                                }
                                selectOptions={universityData}
                              />

                            </ChildGrid>
                            <ChildGrid required={true} label="Percentage">
                              <InputField
                                id="percentage"
                                type="number"
                                placeholder="Enter"
                                name={`educationDetails[${index}].percentage`}
                                value={educationDetails[index].percentage}
                                validateOnChange={true}
                                error={
                                  touched?.educationDetails?.[index]
                                    ?.percentage &&
                                  Boolean(
                                    errors?.educationDetails?.[index]
                                      ?.percentage
                                  )
                                }
                              />
                            </ChildGrid>
                            {
                              (index === values.educationDetails.length - 1 ||
                                values.educationDetails[0].value !== "" || values.educationDetails[index].new) && (
                                <Box className="oh-add-section" sx={{ display: "inline-flex", gap: "10px" }}>
                                  {values.educationDetails[index].new && (
                                    <IconButton
                                      className="oh-delete-btn"
                                      title="Delete"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                  {index === values.educationDetails.length - 1 &&
                                    values.educationDetails[0].value !== "" && (
                                      <IconButton
                                        className="oh-add-btn"
                                        title="Add"
                                        onClick={() => {
                                          push({
                                            year: "",
                                            university: "",
                                            percentage: "",
                                            new: true,
                                          });
                                        }}
                                      >
                                        <AddIcon />
                                        <span>Add</span>
                                      </IconButton>
                                    )}
                                </Box>
                              )
                            }
                          </Grid>
                        );
                      });
                    }}
                  </FieldArray>
                </Grid>
                {/* Experience details section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Experience Details"
                  />
                  <FieldArray name="experienceDetails">
                    {(fieldArrayProps) => {
                      const { form, push } = fieldArrayProps;
                      const { values } = form;
                      const { experienceDetails } = values;
                      return experienceDetails.map((elem, index) => {
                        return (
                          <Grid
                            className="oh-company-details"
                            container
                            key={index}
                          >
                            <ChildGrid required={true} label="Year">
                              <MuiSelect
                                id={experienceDetails[index].id}
                                select
                                placeholder={
                                  !experienceDetails[index].year &&
                                  experienceDetails[index].year != 0 &&
                                  "Select Year"
                                }
                                name={`experienceDetails[${index}].year`}
                                value={experienceDetails[index].year}
                                handleChange={(e) => {
                                  setFieldValue(
                                    `experienceDetails[${index}].year`,
                                    e.target.value
                                  );
                                }}
                                error={
                                  touched?.experienceDetails?.[index]?.year &&
                                  Boolean(
                                    errors?.experienceDetails?.[index]?.year
                                  )
                                }
                                selectOptions={experienceArr}
                                helperText={
                                  <FieldError
                                    errorString={
                                      touched?.experienceDetails?.[index]
                                        ?.year &&
                                      errors?.experienceDetails?.[index]?.year
                                    }
                                  />
                                }
                              />
                            </ChildGrid>
                            <ChildGrid required={true} label="Current Company Name">
                              <InputField
                                id={experienceDetails[index].id}
                                type="text"
                                placeholder="Enter"
                                name={`experienceDetails[${index}].company`}
                                value={experienceDetails[index].company}
                                validateOnChange={true}
                                error={
                                  touched?.experienceDetails?.[index]
                                    ?.company &&
                                  Boolean(
                                    errors?.experienceDetails?.[index]?.company
                                  )
                                }
                              />
                            </ChildGrid>
                            <ChildGrid required={true} label="Designation">
                              <InputField
                                id={experienceDetails[index].id}
                                type="text"
                                placeholder="Enter"
                                name={`experienceDetails[${index}].designation`}
                                value={experienceDetails[index].designation}
                                validateOnChange={true}
                                error={
                                  touched?.experienceDetails?.[index]
                                    ?.designation &&
                                  Boolean(
                                    errors?.experienceDetails?.[index]
                                      ?.designation
                                  )
                                }
                              />
                            </ChildGrid>
                            {
                              (index === values.experienceDetails.length - 1 ||
                                values.experienceDetails[0].value !== "" ||
                                values.experienceDetails[index].new) && (
                                <Box className="oh-add-section" sx={{ display: "inline-flex", gap: "10px" }}>
                                  {values.experienceDetails[index].new && (
                                    <IconButton
                                      className="oh-delete-btn"
                                      title="Delete"
                                      onClick={() => {
                                        fieldArrayProps.remove(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                  {index ===
                                    values.experienceDetails.length - 1 &&
                                    values.experienceDetails[0].value !== "" && (
                                      <IconButton
                                        className="oh-add-btn"
                                        logo={<AddIcon />}
                                        title="Add"
                                        onClick={() => {
                                          push({
                                            year: "",
                                            company: "",
                                            designation: "",
                                            new: true,
                                          });
                                        }}
                                      >
                                        <AddIcon />
                                        <span>Add</span>
                                      </IconButton>
                                    )}
                                </Box>
                              )
                            }
                          </Grid>
                        );
                      });
                    }}
                  </FieldArray>
                </Grid>
                {/* key skills section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Skills"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid
                      label="Key Skills"
                      required={true}
                      size="three_fourth"
                    >
                      <Autocomplete
                        clearIcon={false}
                        options={skillsOpt}
                        value={values.skills}
                        multiple
                        sx={{
                          ".MuiAutocomplete-tag": {
                            // minWidth: "fit-content",
                            padding: "10px",
                            maxWidth: "fit-content",
                            display: "flex",
                            justifyContent: "space-evenly",
                            gap: "5px",
                          },
                          "& .MuiInputBase-root": {
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                            height: "auto",
                            minHeight: "40px",
                            maxHeight: "fit-content",
                            padding: "8px",
                            borderRadius: "30px",
                          },
                        }}
                        style={{ maxHeight: "auto" }}
                        noOptionsText={"No options"}
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <MuiDeletableChip
                              sx={{
                                minWidth: "fit-content",
                                maxWidth: "150px",
                              }}
                              label={option.label}
                              {...props({ index })}
                            />
                          ))
                        }
                        onChange={(e, newValue) => {
                          const remainingOptions = skillsOpt.filter(
                            (option) =>
                              !newValue.find(
                                (selected) => selected.value === option.value
                              )
                          );
                          setSkillsOpt(remainingOptions);
                          setFieldValue("skills", newValue);
                          const removedOptions = values.skills.filter(
                            (option) =>
                              !newValue.find(
                                (selected) => selected.value === option.value
                              )
                          );
                          setSkillsOpt((prevOptions) => [
                            ...prevOptions,
                            ...removedOptions,
                          ]);
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            placeholder={values.skills.length < 1 && "Eg: HTML"}
                            error={touched.skills && Boolean(errors.skills)}
                            helperText={
                              <FieldError
                                errorString={touched.skills && errors.skills}
                              />
                            }
                            param={params}
                          />
                        )}
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>
                {/* other info section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Other Information"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid size="secondary_1200" required={true} label="Your preferred Location">
                      <Autocomplete
                        clearIcon={false}
                        options={locations}
                        value={values.locations}
                        multiple
                        sx={{
                          ".MuiAutocomplete-tag": {
                            // minWidth: "fit-content",
                            padding: "10px",
                            maxWidth: "fit-content",
                            display: "flex",
                            justifyContent: "space-evenly",
                            gap: "5px",
                          },
                          "& .MuiInputBase-root": {
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                            height: "auto",
                            minHeight: "40px",
                            maxHeight: "fit-content",
                            padding: "8px",
                            borderRadius: "30px",
                          },
                        }}
                        style={{ maxHeight: "auto" }}
                        noOptionsText={"No options"}
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <MuiDeletableChip
                              sx={{
                                minWidth: "fit-content",
                                maxWidth: "150px",
                              }}
                              label={option.label}
                              {...props({ index })}
                            />
                          ))
                        }
                        onChange={(e, newValue) => {
                          const remainingOptions = locations.filter(
                            (option) =>
                              !newValue.find(
                                (selected) => selected.value === option.value
                              )
                          );
                          setLocations(remainingOptions);
                          setFieldValue("locations", newValue);
                          const removedOptions = values.locations.filter(
                            (option) =>
                              !newValue.find(
                                (selected) => selected.value === option.value
                              )
                          );
                          setLocations((prevOptions) => [
                            ...prevOptions,
                            ...removedOptions,
                          ]);
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            placeholder={
                              values.locations.length < 1 && "Eg: Indore"
                            }
                            error={
                              touched.locations && Boolean(errors.locations)
                            }
                            helperText={
                              <FieldError
                                errorString={
                                  touched.locations && errors.locations
                                }
                              />
                            }
                            param={params}
                          />
                        )}
                      />
                    </ChildGrid>

                    <ChildGrid size="secondary_1200" required={true} label="Job Category">
                      <Autocomplete
                        clearIcon={false}
                        options={categories}
                        value={values.categories}
                        multiple
                        sx={{
                          ".MuiAutocomplete-tag": {
                            // minWidth: "fit-content",
                            padding: "10px",
                            maxWidth: "fit-content",
                            display: "flex",
                            justifyContent: "space-evenly",
                            gap: "5px",
                          },
                          "& .MuiInputBase-root": {
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                            height: "auto",
                            minHeight: "40px",
                            maxHeight: "fit-content",
                            padding: "8px",
                            borderRadius: "30px",
                          },
                        }}
                        style={{ maxHeight: "auto" }}
                        noOptionsText={"No options"}
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <MuiDeletableChip
                              sx={{
                                minWidth: "fit-content",
                                maxWidth: "150px",
                              }}
                              label={option.label}
                              {...props({ index })}
                            />
                          ))
                        }
                        onChange={(e, newValue) => {
                          const remainingOptions = categories.filter(
                            (option) =>
                              !newValue.find(
                                (selected) => selected.value === option.value
                              )
                          );
                          setCategories(remainingOptions);
                          setFieldValue("categories", newValue);
                          const removedOptions = values.categories.filter(
                            (option) =>
                              !newValue.find(
                                (selected) => selected.value === option.value
                              )
                          );
                          setCategories((prevOptions) => [
                            ...prevOptions,
                            ...removedOptions,
                          ]);
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            placeholder={
                              values.categories.length < 1 && "Eg: Non IT"
                            }
                            error={
                              touched.categories && Boolean(errors.categories)
                            }
                            helperText={
                              <FieldError
                                errorString={
                                  touched.categories && errors.categories
                                }
                              />
                            }
                            param={params}
                          />
                        )}
                      />
                    </ChildGrid>
                    <ChildGrid size="secondary" required={true} label="CTC">
                      <MuiSelect
                        id="ctc"
                        select
                        placeholder={
                          !values.ctc && values.ctc !== 0 && "Select"
                        }
                        name="ctc"
                        value={values.ctc}
                        handleChange={(e) => {
                          setFieldValue("ctc", e.target.value);
                        }}
                        selectOptions={ctcArr}
                        error={touched.ctc && Boolean(errors.ctc)}
                        helperText={
                          <FieldError errorString={touched.ctc && errors.ctc} />
                        }
                      />
                    </ChildGrid>

                    <ChildGrid size="secondary" required={false} label="Expected CTC">
                      <MuiSelect
                        id="ectc"
                        select
                        placeholder={
                          !values.ectc && values.ectc !== 0 && "Select"
                        }
                        name="ectc"
                        value={values.ectc}
                        selectOptions={ctcArr}
                        handleChange={(e) => {
                          setFieldValue("ectc", e.target.value);
                        }}
                        error={touched.ectc && Boolean(errors.ectc)}
                        helperText={
                          <FieldError
                            errorString={touched.ectc && errors.ectc}
                          />
                        }
                      />
                    </ChildGrid>
                  </Grid>
                </Grid>
                {/* other info section */}
                <Grid item className="oh-details-container space-between">
                  <MuiTypography
                    className="oh-details-label"
                    content="Optional Information"
                  />
                  <Grid className="oh-company-details" container>
                    <ChildGrid size="secondary_1200" label="Experties in">
                      <ReactSelect
                        name="expertise_in"
                        options={expertiesIn}
                        placeholder={"Eg: Team Work"}
                        value={values?.experties_in}
                        onChange={(e, newValue) => {
                          if (newValue?.action === "select-option") {
                            const updatedExpertiesIn = [...values?.experties_in, newValue?.option];
                            setFieldValue("experties_in", updatedExpertiesIn);
                          } else if (newValue?.action === "remove-value" || newValue?.action === "pop-value") {
                            const updatedExpertiesIn = values?.experties_in.filter(elem => elem !== newValue?.removedValue);
                            setFieldValue("experties_in", updatedExpertiesIn);
                          } else {
                            setFieldValue("experties_in", []);
                          }
                        }}
                      />

                    </ChildGrid>
                  </Grid>
                </Grid>
                {/* Social Media links */}
                <Grid item className="oh-details-container">
                  <MuiTypography
                    className="oh-details-label"
                    content="Languages"
                  />
                  <Typography
                    // className="oh-details-label"
                    sx={{ color: "#a1a7bc", fontSize: "12px" }}
                  >
                    Kindly rate your proficiency in languages below.
                  </Typography>

                  <Grid
                    className="oh-company-details column-field radio-group"
                    container
                  >
                    <ChildGrid>
                      <MuiTypography
                        className="oh-details-label"
                        content="English"
                      />
                      <Box
                        sx={{
                          "& > legend": { mt: 2 },
                        }}
                      >
                        <StyledRating
                          name="customized-color"
                          value={values.english}
                          // value={engRatingValue}
                          onChange={(event, newValue) => {
                            // setEngRatingValue(newValue);
                            setFieldValue("english", newValue);
                          }}
                          getLabelText={(value) =>
                            `${value} Heart${value !== 1 ? "s" : ""}`
                          }
                          size="medium"
                          sx={{ marginTop: "10px", gap: 3 }}
                          precision={0.5}
                          icon={<FavoriteIcon fontSize="inherit" />}
                          emptyIcon={
                            <RadioButtonCheckedIcon fontSize="inherit" />
                          }
                        />
                      </Box>
                    </ChildGrid>

                    <ChildGrid size="secondary">
                      <MuiTypography
                        className="oh-details-label"
                        content="Hindi"
                      />
                      <Box
                        sx={{
                          "& > legend": { mt: 2 },
                        }}
                      >
                        <StyledRating
                          name="customized-color"
                          value={values.hindi}
                          // value={hinRatingValue}
                          onChange={(event, newValue) => {
                            // setHinRatingValue(newValue);
                            setFieldValue("hindi", newValue);
                          }}
                          getLabelText={(value) =>
                            `${value} Heart${value !== 1 ? "s" : ""}`
                          }
                          size="medium"
                          sx={{ marginTop: "10px", gap: 3 }}
                          precision={0.5}
                          icon={<FavoriteIcon fontSize="inherit" />}
                          emptyIcon={
                            <RadioButtonCheckedIcon fontSize="inherit" />
                          }
                        />
                      </Box>
                    </ChildGrid>
                  </Grid>
                </Grid>
              </Grid>
              <MuiDivider className="oh-details-divider" />
              <CustomLoadingButton
                style={!dirty ? { opacity: 0.5 } : null}
                disabled={!dirty ? true : false}
                loading={isSubmitting}
                type="submit"
                title={"Submit"}
                className="oh-submit-btn"
              />
            </Form>
          );
        }}
      </Formik>
    </ProfileLayout>
  );
};

function InputField({ InputProps, name, validateOnChange = false, ...rest }) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    if (validateOnChange) {
      setFieldTouched(name, true, false);
    }
    setFieldValue(name, event.target.value, true);
  };

  const error = (validateOnChange || meta.touched) && meta.error;

  return (
    <div>
      <MuiTextField
        {...field}
        {...rest}
        onChange={handleChange}
        helperText={meta.touched && error && <FieldError errorString={error} />}
        InputProps={InputProps && InputProps}
      />
    </div>
  );
}

export default OtherDetails;
